import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSocket } from '../hooks/useSocket';

export function Register(props) {
  const { socket } = useSocket()
  const [Password, setPassword] = useState('')
  const [Name, setName] = useState('')
  const dispatch = useDispatch();

  return (
    <div className='d-flex justify-content-center'>
      <div className='w-25 mt-5'>
        <div className="m-3">
            <input type="text" className="form-control" value={Name} placeholder="Имя" onChange={(e) => setName(e.target.value)}/>
        </div>
        <div className="m-3">
            <input id="password-field" type="password" className="form-control" value={Password} placeholder="Пароль" onChange={(e) => setPassword(e.target.value)}/>
        </div>
        <div className="form-element">
            <button className="btn btn-secondary m-3" 
              onClick={() => {
                console.log('send reg')
                socket.emit('registration', {'username': Name, 'password': Password})
              }}
              >
                {'Зарегистрироваться'}
            </button>
        </div>
      </div>
    </div>
  );
}
