import React, { useEffect, useState } from 'react'
import { sendNewFranch } from '../lib/funcs'

export default function NewFranch(props) {
    const setShowFormNewFranch = props.setShowFormNewFranch
    const user_id = props.user_id
    const [username, setUsername] = useState('')
    const [usermail, setUsermail] = useState('')
    const [mailPassword, setMailPassword] = useState('')
    const [checked, setchecked] = useState(false);

    const handleCheckbox = ({ target: { checked } }) => {
        console.log('checked', checked);
        setchecked(checked);
      };
    return (
        <>
            <div className={props.isMobile ? 
                'position-absolute top-50 start-50 translate-middle w-75 h-35vh form-border bg-grey': 
                "position-absolute top-50 start-50 translate-middle w-25 h-35vh form-border bg-grey"}
            >
                <div className='d-flex justify-content-center flex-column p-2'>
                    <p>{'Новый франчайзи'}</p>
                    <input className='bg-white m-2' placeholder="Имя" value={username} 
                        onChange={(e) => {setUsername(e.target.value)}}>
                    </input>
                    {/* <input className='bg-white m-2' placeholder="Email" value={usermail} 
                        onChange={(e) => {setUsermail(e.target.value)}}>
                    </input> */}
                    <div className='w-100 d-flex justify-content-start align-items-center ps-2'>
                        <input id='ch' type="checkbox" checked={checked} onChange={handleCheckbox} />
                        <label className='ms-3' for='ch'>Модератор</label>
                    </div>
                    
                    <input className='bg-white m-2' placeholder="Email для пароля" value={mailPassword} 
                        onChange={(e) => {setMailPassword(e.target.value)}}>
                    </input>
                    <div className='w-100 row d-flex justify-content-center m-2'>
                        <button className='col-3 m-1 btn btn-light fs-12 p-0 py-2' onClick={() => {
                                props.handleNewFranch(username, usermail, mailPassword, checked)
                                setShowFormNewFranch(false)                                
                            }}>
                            {'Создать'}
                        </button>
                        <button className='col-3 m-1 btn btn-light fs-12 p-0' onClick={() => {
                            setShowFormNewFranch(false)
                        }}>
                            {'Отмена'}
                        </button>
                    </div>
                </div>
                
                    
            </div>
        </>
    )
}