import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const IMGFILES = ['png', 'jpg', 'peg', 'jpeg', 'PNG', 'JPG', 'JPEG']

export function ExportMessageItem(props) {
  const text = props.text
  const username = props.username
  const isOwn = props.isOwn
  const filepath = props.filepath
  const filetype = props.filetype
  const filename = props.filename
  const id = props.id
  const isfav = props.isfav
  const infoanswer = props.infoanswer
  const textDate = props.textDate
  const textTime = props.textTime
  const isMobile = props.isMobile
  // const files = props.files


  return (
    <>
      <div id={id.toString()} className={isOwn ? 'd-flex justify-content-end': 'd-flex justify-content-start'}>
        
        <div className={
          isOwn ? 
            (isMobile ? 
              'bg-messageitem m-1 w-75 rounded-3 border border-secondary': 
              'bg-messageitem m-1 w-75 rounded-3 border border-secondary'): 
            (isMobile ? 'm-1 w-75 rounded-3 bg-messageitemR border border-secondary': 'p-2 m-1 w-75 rounded-3 bg-messageitemR border border-secondary')
        }>
          {/* answer, favorite */}
          <div className='d-flex justify-content-between'>
            <div className='text-start text-secondary m-1 fs-10 w-25'>
              {username}{props.ind}
            </div>
          </div>
          

          {/* answer message */}
          {infoanswer != '' ? (
            <>
              <div className='bg-answermessage  rounded-3 align-bottom ms-1 me-4 position-relative'>
                  <div className='text-start text-secondary m-1 fs-10'>
                      {infoanswer['username']}
                  </div>
                  <div className='text-start m-1 text-wrap text-break'>
                    <p>{infoanswer['text']}</p>
                  </div> 
                  {infoanswer['filepath'] != null ? (
                      <div className='d-flex justify-content-start col'>
                          {IMGFILES.includes(infoanswer['filetype']) ? (
                              <>
                                  <img style={{maxHeight: '700px'}} className='m-1 rounded-3 h-5' src={props.urlh + 'static/uploads/' + infoanswer['filepath']} />
                              
                              </>
                              ): null}
                              {infoanswer['filetype'] == 'mp4' ? (
                                  <p>Video</p>
                              ): null}
                              {infoanswer['filetype'] != 'mp4' && !IMGFILES.includes(infoanswer['filetype']) ? (
                              
                              <p>File</p>
                              ): null}
                      </div>
                  ): null}                    
              </div>  
            </>
          ): null}
          {filepath != '' &&  filepath != null ? (
            <>
              <div className='d-flex justify-content-start '>
                  {IMGFILES.includes(filetype) ? (
                      <>
                          <img style={{maxHeight: '700px'}} className='m-1 rounded-3 w-100 text-message' src={props.urlh + 'static/uploads/' + filepath} />
                        
                      </>
                    ): null}

                    {filetype == 'mp4' ? (
                      <p>Video</p>
                    ): null}

                    {filetype == 'pdf' || filetype == 'txt' || filetype == 'PDF'? (
                      <p>PDF</p>
                        ): null}

                    {filetype != 'mp4' && !IMGFILES.includes(filetype) && filetype != 'ogg' ? (
                      <p>File</p>
                    ): null}
                    {filetype == 'ogg' ? (
                      <>
                        <p>Audio</p>
                      </>
                    ): null}
              </div>
              <p className='d-flex justify-content-start m-1 fs-12'>{filename}</p>
            </>
            ): null} 

          <div className='text-start m-1 text-wrap text-break'>
              <p className='wa'>{text}</p>
          </div>
          <div className='d-flex justify-content-end p-1'>
            {isfav ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
              </svg>
            ): ''}
          </div>   
          <div className='d-flex justify-content-between'>
            <p className='ms-1 mb-1 fs-10'>{textTime}</p>
            <p className='p-0 m-0'>Проверено</p>
            <p className='me-1 mb-1 fs-10'>{textDate}</p>
          </div>
        </div>
      </div>
    </>
  )
}