import React, { useEffect, useState } from 'react'

export default function SelectUser(props) {
    const ListUsers = props.ListUsers
    const setShowSelectUser = props.setShowSelectUser
    const isMobile = props.isMobile
    const [selectedIdUser, setSelectedIdUser] = useState(0)
    const [selectedNameUser, setSelectedNameUser] = useState('')
    return (
        <>
            <div className={isMobile ? "position-absolute top-50 start-50 translate-middle w-75 h-50 form-border bg-lgrey": "position-absolute top-50 start-50 translate-middle w-25 h-50 form-border bg-lgrey"}>
                <div className='d-flex justify-content-center flex-column p-2 h-100'>
                <div className="p-2 h-100">
                        <h3 className="my-2">{'Участники'}</h3>
                        <div className="d-flex justify-content-start h-100">
                            <div className='w-100 h-100'>
                                <div>
                                    <div className='row'>
                                        <div className='col-4 fw-bold my-3'>
                                            {'Имя'}
                                        </div>
                                        {/* <div className='col-2 fw-bold'>
                                            {'Статус'}
                                        </div> */}
                                    </div>
                                </div>
                                <div className='h-50 overflow-auto'>
                                    {ListUsers.map(user => {
                                        return (
                                            <>
                                                <div 
                                                    className={user.id == selectedIdUser ? "d-flex justify-content-start bg-grey border-grey-bottom py-2": "d-flex justify-content-start link-item border-grey-bottom py-2" }
                                                    key={user.id}
                                                    onClick={() => {
                                                        setSelectedIdUser(user.id)
                                                        setSelectedNameUser(user.username)
                                                    }}
                                                >
                                                    {user.username}
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <div className='w-100 row d-flex justify-content-center align-self-end m-2 '>
                                    <button className='btn btn-secondary col-5 m-1' onClick={() => {
                                            if (selectedIdUser == 0) {
                                                alert('Выберите участника')
                                            } 
                                            else {
                                                props.handleAddUser(selectedIdUser, selectedNameUser)
                                                setShowSelectUser(false) 
                                            }                               
                                        }}>
                                        {'Добавить'}
                                    </button>
                                    <button className='btn btn-secondary col-5 m-1' onClick={() => {
                                        setShowSelectUser(false)
                                    }}>
                                        {'Отмена'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}