import  client  from "socket.io-client";
// import { url } from "./constants";

const socket = client('https://u102315.na4u.ru');

export function useSocket () {
    
    return {
        socket
    }
}