import { createSlice } from '@reduxjs/toolkit';



const initialState = {
    arrived_new_message: false,
    arrived_new_moder_message: false,
    arrived_new_msg_chatname: ''
};


export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setArrivedNewMessage: (state, action) => {
      state.arrived_new_message = action.payload;
    },
    setArrivedNewModerMessage: (state, action) => {
      state.arrived_new_moder_message = action.payload;
    },
    setArrivedNewMsgChatName: (state, action) => {
      state.arrived_new_msg_chatname = action.payload;
    },
  },

});

export const { setArrivedNewMsgChatName, setArrivedNewMessage, setArrivedNewModerMessage } = mainSlice.actions;

export const selectArrivedNewMessage = (state) => state.main.arrived_new_message;
export const selectArrivedNewModerMessage = (state) => state.main.arrived_new_moder_message;
export const selectArrivedNewMsgChatName = (state) => state.main.arrived_new_msg_chatname;

export default mainSlice.reducer;
