import React, { useState, useEffect } from 'react'

export default function Status(props) {
    const statusname = props.statusname
    const id = props.id

    return (
        <>
            <div className='row m-1'>
                <div className={
                    props.StatusId == id ? 
                        'text-start col-12 border-black border-grey-bottom': 
                        'text-start link-item col-12 border-grey-bottom' 
                    }
                onClick={() => {
                    console.log(statusname)
                    props.handleSetStatus(id)
                }}>
                    {statusname}
                </div>
            </div>
            
        </>
    )
}