import React, { useEffect, useState } from 'react'

export default function NewUser(props) {
    const setShowFormNewUser = props.setShowFormNewUser
    const [username, setUsername] = useState('')
    const [usermail, setUsermail] = useState('')
    const [mailPassword, setMailPassword] = useState('')
    return (
        <>
            <div className={
                props.isMobile ? 
                    '':
                    ""}>
                <div className='d-flex justify-content-center flex-column p-2'>
                    <p>{'Новый участник'}</p>
                    <input className='bg-white m-2 form-control' placeholder="Имя" value={username} 
                        onChange={(e) => {setUsername(e.target.value)}}>
                    </input>
                    {/* <input className='bg-white m-2' placeholder="Email" value={usermail} 
                        onChange={(e) => {setUsermail(e.target.value)}}>
                    </input> */}
                    <input className='bg-white m-2 form-control' placeholder="Email для пароля" value={mailPassword} 
                        onChange={(e) => {setMailPassword(e.target.value)}}>
                    </input>

                    <div className='w-100 row d-flex justify-content-center m-2'>
                        <button className='col-3 m-1 btn btn-light fs-12 p-0 py-2' onClick={() => {
                                props.handleNewUser(username, usermail, mailPassword)
                                setShowFormNewUser(false)                                
                            }}>
                            {'Создать'}
                        </button>
                        <button className='col-3 m-1 btn btn-light fs-12 p-0 py-2' onClick={() => {
                            setShowFormNewUser(false)
                        }}>
                            {'Отмена'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}