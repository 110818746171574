import React, { useState, useEffect } from 'react'
import { sendProjectsOfUser } from '../lib/funcs'

export default function UserItem(props) {
    const ShowUserInfo = props.ShowUserInfo
    const setShowUserInfo = props.setShowUserInfo
    const handleProjectsOfUser = props.handleProjectsOfUser
    const [showMyUserInfo, setShowMyUserInfo] = useState(false)
    const [listProject, setListProject] = useState([])
    const username = props.username
    const id = props.id
    const is_stop = props.is_stop
    const isMobile = props.isMobile

    


    

    return (
        <>
            
            <div className='row m-1 border-grey-bottom py-2'>
                <div className='text-start link-item col-5' onClick={() => {
                    if (ShowUserInfo) {
                        setShowUserInfo(false)
                    }
                    else {
                        console.log(username)
                        handleProjectsOfUser(id)
                        setShowUserInfo(true)                        
                    }
                }}>
                    {username}
                </div>
                {props.isFranch && props.location == 'all' ? (
                    <>
                        <div className='col-4 p-0 m-0'>
                            <button className='btn btn-secondary btn-sm font-s w-100' onClick={() => {
                                props.handleStopUser(id, is_stop)
                            }}>
                                {is_stop ? 'Возобновить': 'Остановить'}
                            </button>
                        </div>
                        <div className='col-3'>
                            <button className='btn btn-secondary btn-sm font-s' onClick={() => {
                                if (window.confirm('Вы уверенны?')) {
                                    props.handleDeleteUser(id)
                                }
                            }}>
                                {'Удалить'}
                            </button>
                        </div>
                    </>
                ): (
                    <>
                        <div className='col-3'>

                        </div>
                    </>
                )}
                {props.isFranch && props.location == 'project' ? (
                    <>
                        <div className='col-4 p-0 m-0'>
                            <button className='btn btn-secondary font-s w-100 py-2 m-0' onClick={() => {
                                props.handleTakeOffUser(id, is_stop)
                            }}>
                                {'Снять с проекта'}
                            </button>
                        </div>
                    </>
                ): null}
            </div>
            
        </>
    )
}