import React, { useEffect, useState } from 'react'
import FileItem from './fileitem'
import TextareaAutosize from 'react-textarea-autosize';
import { getOrder } from '../lib/funcs';

const IMGFILES = ['png', 'jpg', 'peg', 'jpeg', 'JPG', 'PNG', 'JPEG']

export function FormOrder(props) {
    
    const [files_input, setFiles_input] = useState([])
    const [contentForm, setContentForm] = useState('form-fields') // form-fields, form-file
    const [selectFile, setSelectFile] = useState(null)
    const [XHR, setXHR] = useState(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState('')
    const [nameOrder, setNameOrder] = useState('')
    const [product, setProduct] = useState('')
    const [necessary, setNecessary] = useState(1)
    const [additive, setAdditive] = useState(1)
    const [comment, setComment] = useState('')
    const [email_recipient, setEmail_recipient] = useState('')
    

    let xhr = new XMLHttpRequest();

    function delFileMessage(ind) {
        let files_arr = files_input.slice() 
        let new_arr = []                 
        for (let i = 0; i < files_arr.length; i++) {
            if (files_arr[i]['ind'] !== ind) {
                new_arr.push(files_arr[i]);
            }
        }
        setFiles_input(new_arr)
    }

    function handleClickSendOrder () {
        let formData = new FormData();
        for (let i = 0; i < files_input.length; i++) {
            formData.append('message_files', files_input[i]['file']);
        }                                   
        
        formData.append("id_user", props.id_user);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("city", city);
        formData.append("nameOrder", nameOrder);
        formData.append("product", product);
        formData.append("necessary", necessary);
        formData.append("additive", additive);
        formData.append("comment", comment);
        formData.append("email_recipient", email_recipient);

        xhr.open('POST', props.urlh + 'order')
        xhr.upload.addEventListener('progress', function(e) {
            let percent_completed = (e.loaded / e.total)*100;
            console.log(percent_completed);
            // setPercentCompleted(Math.round(percent_completed))
        });
        xhr.addEventListener('load', function(e) {
            alert('Данные отправлены')
        });
        xhr.addEventListener('abort', event => {
            alert('Отправка отменена')
        })
        // setShowMessageProcess(true)
        xhr.send(formData)
        setXHR(xhr)
        
        setFiles_input([])
    }

    const handleChangeName = (e) => {
        setName(e.target.value)
    }
    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleChangePhone = (e) => {
        setPhone(e.target.value)
    }
    const handleChangeCity = (e) => {
        setCity(e.target.value)
    }
    const handleChangeNameOrder = (e) => {
        setNameOrder(e.target.value)
    }
    const handleChangeProduct = (e) => {
        setProduct(e.target.value)
    }
    const handleChangeNecessary = (e) => {
        setNecessary(e.target.value)
    }
    const handleChangeAdditive = (e) => {
        setAdditive(e.target.value)
    }
    const handleChangeEmailRecipient = (e) => {
        setEmail_recipient(e.target.value)
    }    

    function handleGetOrder() {
        getOrder(props.id_user, function(data) {
            console.log('handleClickSendOrder', data)
            if (data.res) {
                setName(data.data.name)
                setEmail(data.data.email)
                setPhone(data.data.phone)
                setCity(data.data.city)
                setEmail_recipient('rusprofproekt@mail.ru')
                setNecessary(data.data.necessary)
                setAdditive(data.data.additive)
            }
          }
        )
    }

    useEffect(() => {       
        handleGetOrder()
    }, [])
    return (
        <>
            <div className={props.isMobile ? 'form-order-m mt-5': "form-order mt-5"}>
                <div className={props.isMobile ? '': 'row'}>
                    <div className={props.isMobile ? 'd-flex justify-content-between mb-2': 'col-5'}>
                        <h5>Новый заказ</h5>
                        <button className='btn btn-secondary btn-sm' onClick={() => props.setShowModalOrder(false)}>
                            Закрыть
                        </button>
                    </div>
                    <div className={props.isMobile ? '': 'col-7'}>
                        <div className="input-group">
                            <span className="input-group-text font-s" >email куда отправить</span>
                            <input type="text" className="form-control font-s" value={email_recipient} onChange={handleChangeEmailRecipient}/>
                        </div>
                    </div>
                    
                </div>
                {contentForm === 'form-fields' ? (
                    <div className='mt-3'>
                        <div className='row m-1'>
                            <div className='col-5 font-s'>Имя</div>
                            <div className='col-7'>
                                <input type="text" className="form-control font-s h-4" value={name} onChange={handleChangeName}/>
                            </div>
                        </div>
                        <div className='row m-1'>
                            <div className='col-5 font-s'>Эл. почта</div>
                            <div className='col-7'>
                                <input type="text" className="form-control font-s h-4" value={email} onChange={handleChangeEmail}/>
                            </div>
                        </div>
                        <div className='row m-1'>
                            <div className='col-5 font-s'>Тел/whatsap</div>
                            <div className='col-7'>
                                <input type="text" className="form-control h-4 font-s"  value={phone} onChange={handleChangePhone}/>
                            </div>
                        </div>
                        <div className='row m-1'>
                            <div className='col-5 font-s'>Город</div>
                            <div className='col-7'>
                                <input type="text" className="form-control h-4 font-s" value={city} onChange={handleChangeCity}/>
                            </div>
                        </div>
                        <div className='row m-1'>
                            <div className='col-5 font-s'>Название заказа</div>
                            <div className='col-7'>
                                <input type="text" className="form-control h-4 font-s" value={nameOrder} onChange={handleChangeNameOrder}/>
                            </div>
                        </div>
                        <div className='row m-1'>
                            <div className='col-5 font-s'>Изделие</div>
                            <div className='col-7'>
                                <input type="text" className="form-control h-4 font-s" value={product} onChange={handleChangeProduct}/>
                            </div>
                        </div>
                        <div className='row m-1'>
                            <div className='col-5 font-s'>Что необходимо?</div>
                            <div className="col-7">
                                <select className="form-select font-s  h-4" value={necessary} onChange={handleChangeNecessary}>
                                    {/* <option selected>Что необходимо?</option> */}
                                    <option value="1">Только модель</option>
                                    <option value="2">Только визуализация для согласования</option>
                                    <option value="3">Только оформление для изготовления</option>
                                    <option value="4">Модель и визуализация для осгласования</option>
                                    <option value="5">Визуализация для согласования и оформление для производства</option>
                                    <option value="6">Модель и оформление для производства</option>
                                    <option value="7">Все вышеперечисленное</option>
                                </select>
                            </div>
                        </div>
                        <div className='row m-1'>
                            <div className='col-5 font-s'>С присадкой?</div>
                            <div className="col-7">
                                <select className="form-select  font-s  h-4" value={additive} onChange={handleChangeAdditive}>
                                    {/* <option selected>Что необходимо?</option> */}
                                    <option value="1">Да</option>
                                    <option value="2">Нет</option>
                                </select>
                            </div>
                        </div>
                        <TextareaAutosize   
                                        type="text" 
                                        className="form-control nores mt-3" 
                                        value={comment} 
                                        placeholder="Материалы и пожелания" 
                                        onChange={(e) => {
                                                setComment(e.target.value)
                                            }
                                        }
                                    />
                    </div>
                ): null}

                {contentForm === 'form-file' ? (
                    <div className='mt-3'>
                        <div className='d-flex justify-content-end link-cursor font-s' onClick={() => setContentForm('form-fields')}>
                            Назад
                        </div>
                        <div className='file-preview'>
                            {IMGFILES.includes(selectFile.name.slice(-3)) ? (
                                <img className='mw-100 mh-100' src={URL.createObjectURL(selectFile)} alt='ошибка содержимого'/>
                            ): null}

                            {selectFile.name.slice(-3) === 'mp4' ? (
                                <video className='mw-100 mh-100' controls="controls" >
                                    <source src={URL.createObjectURL(selectFile)} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                                </video>
                            ): null}

                            {selectFile.name.slice(-3) === 'pdf' || selectFile.name.slice(-3) === 'txt' ? (
                                <object height="100%" aria-label='error'
                                    width="100%" className='mw-100 mh-100' type="text/plain" data={URL.createObjectURL(selectFile)}
                                />
                            ): null}

                            {selectFile.name.slice(-3) === 'ogg' ? (
                                <audio
                                    controls
                                    src={URL.createObjectURL(selectFile)}>
                                        Your browser does not support the
                                        <code>audio</code> element.
                                </audio>
                            ): null}

                        </div>
                    </div>
                ): null}

                <div className='row mt-3'>
                    <div className={props.isMobile ? 'img-upload col-7': 'img-upload col-10'}>
                        <label className='btn btn-light' for="myfile">
                            Выбрать файлы
                        </label>
                        <input  id='myfile' name='myfile' type="file" multiple 
                            onChange={() => {
                                let files = document.getElementById("myfile")
                                
                                let files_arr = files_input.slice() 
                                let ind = files_arr.length 

                                //test quantity and size of files

                                if (files_arr.length + files.files.length > 12) {
                                    alert('Количество файлов должно быть не более 12')
                                } 
                                else {
                                    let sizeIsCorrect = true
                                    for (let i = 0; i < files.files.length; i++) {
                                        if (files.files[i].size > 1073741824) {
                                            sizeIsCorrect = false
                                            break
                                        }
                                    }

                                    if (sizeIsCorrect) {
                                        for (let i = 0; i < files.files.length; i++) {
                                            // console.log('size', files.files[i].size)
                                            files_arr.push({'ind': ind, 'file': files.files[i]});
                                            ind += 1
                                        }
                                        setFiles_input(files_arr)
                                        // setSelectFile(files_arr[indSelectFile]['file'])
                                        // console.log('addfile true')
                                        // setContentMain('files')
                                    }
                                    else {
                                        alert('Размер одного файла не должен превышать 1Гб')
                                    }
                                    
                                }                     
                                
                            }}
                        />
                    </div>
                    <div className='col-2'>
                        <button type="button" className="btn btn-dark" onClick={() => {handleClickSendOrder()}}> 
                            Отправить
                        </button>
                    </div>
                </div>

                <div className='row mb-4 mx-2 mt-2'>
                        {files_input.map(f => {
                            return <FileItem 
                                    isMobile={props.isMobile}
                                    key={f['ind']} 
                                    ind={f['ind']}
                                    // indSelectFile={indSelectFile}
                                    fileObj={f['file']} 
                                    setSelectFile={setSelectFile}
                                    // setIndSelectFile={setIndSelectFile}
                                    delFileMessage={delFileMessage}
                                    setContentForm={setContentForm}
                                    // isMobile={isMobile}
                                />
                        })}
                </div>
            </div>
        </>
    )
}