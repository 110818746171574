import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import {doLoginAsync, selectIsLogin, selectUserId, doUnLoginAsync, doRegAsync} from './chatSlice'
import TextareaAutosize from 'react-textarea-autosize';

import MessageItem from "./MessageItem"

import FileItem from './FileItem';
import styles from './chat.module.css';

import MyCamera from './camera'
import AudioRec from './audiorec'

import {dateMessage, sendChat, sendMessage, sendSetFavorite, sendUsersIsRead} from '../lib/funcs'

import ReactToPrint from 'react-to-print';
import {ExportChat} from './exportChat';
// import socket from '../lib/funcs'
import {useSocket} from '../hooks/useSocket'
import { saveAs } from 'file-saver'
import { setArrivedNewModerMessage, setArrivedNewMsgChatName } from '../mainSlice';


const IMGFILES = ['png', 'jpg', 'peg', 'jpeg']



export function Chat(props) {
    const {socket} = useSocket()
    const dispatch = useDispatch()

    const [ListMessages, setListMessage] = useState([])
    const [Socket, setSocket] = useState(null)
    const [Message, setMessage] = useState('')
    const [showAnswerMessage, setShowAnswerMessage] = useState(false)
    const [usernameAM, setusernameAM] = useState('')
    const [textAM, settextAM] = useState('')
    const [filepathAM, setfilepathAM] = useState('')
    const [filetypeAM, setfiletypeAM] = useState('')
    const [numFiles, setNumFiles] = useState(0)

    const [selectFile, setSelectFile] = useState(null)
    const [files_input, setFiles_input] = useState([])
    const [indSelectFile, setIndSelectFile] = useState(0)
    
    const [IdAnswer, setIdAnswer] = useState(-1)  
    const [ShowFind, setShowFind] = useState(false)
    
    const [TextFind, setTextFind] = useState('')

    const [percentCompleted, setPercentCompleted] = useState(0)
    const [showMessageProcess, setShowMessageProcess] = useState(false)
    const [XHR, setXHR] = useState(null)

    const [showSendButton, setShowSendButton] = useState(false)
    const [contentMain, setContentMain] = useState('messages')

    const [leftInd, setLeftInd] = useState(0)
    const [rightInd, setRightInd] = useState(0)

    const [id_message_not_is_read, setId_message_not_is_read] = useState(-1)

    const [ListUsersIsRead, setListUsersIsRead] = useState([])
    const [ShowUsersIsRead, setShowUsersIsRead] = useState(false)
    const [isAudio, setIsAudio] = useState(false)
    
    const [showCheckBoxMessage, setShowCheckBoxMessage] = useState(false)
    const [listRowsForExport, setListRowsForExport] = useState([])

    var left_ind = 0
    var right_ind = 0
    // var id_message_not_is_read = -1

    const preShowMessage = props.preShowMessage
    // const [tick, setTick] = useState(0)
    var tick = 0
    let currentTick = 0
    const isMobile = props.isMobile
    const ShowMobileMenu = props.ShowMobileMenu
    const setShowMobileMenu = props.setShowMobileMenu
    let listMess = []

    let xhr = new XMLHttpRequest();
    

    let componentRef

    // const downloadImage = () => {
    //     console.log('download')
    //     saveAs(SrcPreShowMessage, nameDownloadImg)
    //   }

    function setScroll() {
        
        var objDiv = document.getElementById("chat");
        objDiv.scrollTop = objDiv.scrollHeight
        // console.log('scroll', objDiv.scrollHeight)
    }

    function setScroll0() {
        
        var objDiv = document.getElementById("chat");
        objDiv.scrollTop = 210
        // console.log('scroll', '0')
    }

    function handleListMessage(arr) {
        listMess = arr
        setListMessage(arr)
        setRightInd(arr.length)
    }

    function addNewMessagetoListMessages(newmessage) {
        // let arr = ListMessages.slice()
        // arr.push(newmessage)
        // setListMessage(arr)
        
        let arr = listMess.slice()
        // console.log('listMess', listMess)
        arr.push(newmessage)
        // console.log(arr)
        handleListMessage(arr)

        var objDiv = document.getElementById("chat");
        objDiv.scrollTop = objDiv.scrollHeight
    }

    function handleNewMessage(data) {
        displayNotification()
        // console.log(ListMessages.length)
        // console.log('newmessagechat', data)
        addNewMessagetoListMessages(data['newmessage'])
        // dispatch(setId(data.id))
    }

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    function setContentChat(messages, idMessageNotIsRead) {
        // console.log('setContentChat')
        // var objDiv = document.getElementById("chat");
        let id_mess_show = -1
        if (props.idMessageShow != -1) {
            id_mess_show = props.idMessageShow
            setId_message_not_is_read(props.idMessageShow)
        }
        else if (idMessageNotIsRead != -1) {
            id_mess_show = idMessageNotIsRead
            setId_message_not_is_read(idMessageNotIsRead)
        }

        if (id_mess_show != -1) {
            // console.log('not isread', messages.length)
            let is_find = 0
            for (let i=0; i<messages.length - 10; i++) {
                if (messages[i].id == id_mess_show) {
                    setLeftInd(i)
                    left_ind = i
                    right_ind = messages.length
                    is_find = 1
                    break
                }
                
            }
            if (is_find == 0) {
                setLeftInd(messages.length - 20)
                setRightInd( messages.length)
                left_ind = messages.length - 20
                right_ind = messages.length
                
            }

            // var objDiv = document.getElementById("chat");
            // console.log('props.idMessageShow', props.idMessageShow)
            // let divMessage = document.getElementById(props.idMessageShow);
            // console.log('divMessage.offsetTop', divMessage.offsetTop)
            // objDiv.scrollTop = divMessage.offsetTop - 146;
            props.setidMessageShow(-1)
        }
        else {
            // console.log(' isread', messages.length)
            if (messages.length > 20) {
                setLeftInd(messages.length - 20)
                left_ind = messages.length - 20
                right_ind = messages.length
            }
            else {
                setLeftInd(0)
                left_ind = 0
                right_ind = messages.length
            }
            // setScroll()

            // if (idMessageNotIsRead == -1 || idMessageNotIsRead == undefined) {
            //     objDiv.scrollTop = objDiv.scrollHeight
            // }
            // else {
            //     let divMessage = document.getElementById(idMessageNotIsRead + 'f');
            //     if (divMessage !== null && divMessage !== undefined) {
            //         objDiv.scrollTop = divMessage.offsetTop - 120;
            //     }
            // }
        }
        
        setRightInd( messages.length)
    }

    function newMessage(messages) {
        socket.on('newmessagechat', (data) => {handleNewMessage(data)})
        listMess = messages
        setListMessage(messages)        
    }

    function delFileMessage(ind) {
        let files_arr = files_input.slice() 
        let new_arr = []                 
        for (let i = 0; i < files_arr.length; i++) {
            if (files_arr[i]['ind'] != ind) {
                new_arr.push(files_arr[i]);
            }
        }
        setFiles_input(new_arr)
        if (indSelectFile == ind) {
            setIndSelectFile(0)
            setSelectFile(files_input[0]['file'])
        }
        if (new_arr.length == 0) {
            setContentMain('messages')
        }
    }

    function setFavoriteMessage(id_message) {
        let arr = ListMessages.slice()
        arr.forEach((message) => {
            if (message.id == id_message) {
                message.isfav = true
            }
        })
        setListMessage(arr)
    }

    function prepareListRowForExport() {
        let arr = []
        ListMessages.forEach((message) => {
            if (message.ischeck) {
                arr.push(message)
            }
        })
        setListRowsForExport(arr) 
    }

    function setCheckMessagesFalse() {
        let arr = ListMessages.slice()
        arr.forEach((message) => {
            if (message.ischeck) {
                message.ischeck = false
            }
        })
        setListMessage(arr)
    }

    function setCheckMessagesTrue() {
        let arr = ListMessages.slice()
        arr.forEach((message) => {
            if (!message.ischeck) {
                message.ischeck = true
            }
        })
        setListMessage(arr)
    }

    function setFavorite( id ) {
        // Socket.emit('setfavorite', {'id_user': props.UserId, 'id_message': id})
        // console.log('setFavorite', id)
        sendSetFavorite(props.UserId, id, function (data) {
            if (data.res) {
                setFavoriteMessage(id)
            }
        })
    }

    function setInpFocus(id, username, text, filepath, filetype) {
        setusernameAM(username)
        settextAM(text)
        setfilepathAM(filepath)
        setfiletypeAM(filetype)
        setIdAnswer(id)
        setShowAnswerMessage(true)
        // console.log('foc', filetype)
        document.getElementById("inputmessage").focus()
    }

    function dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

    function findText(text) {
        // console.log(text.toLowerCase())
        for (let i=0; i < ListMessages.length; i++) {
            if (ListMessages[i].text.toLowerCase().indexOf(text.toLowerCase()) != -1 || 
                ListMessages[i].filetype.toLowerCase().indexOf(text.toLowerCase()) != -1 || 
                ListMessages[i].filename.toLowerCase().indexOf(text.toLowerCase()) != -1) 
            {
                    // console.log('find', ListMessages[i].id)
                    var objDiv = document.getElementById("chat");

                    if (leftInd > i) {
                        setLeftInd(i)

                    }
                    else {
                        let divMessage = document.getElementById(ListMessages[i].id.toString() + 'f');
                        if (divMessage !== null && divMessage !== undefined) {
                            objDiv.scrollTop = divMessage.offsetTop - 100;
                        }  
                    }
                    
            }
        }
    }

    function findAnswerMessage(id_message) {
        for (let i=0; i < ListMessages.length; i++) {
            if (ListMessages[i].id == id_message ) 
            {
                    // console.log('find', ListMessages[i].id)
                    var objDiv = document.getElementById("chat");

                    if (leftInd > i) {
                        setLeftInd(i-1)
                        setTimeout(setScroll0, 500)

                    }
                    else {
                        let divMessage = document.getElementById(ListMessages[i].id.toString() + 'f');
                        if (divMessage !== null && divMessage !== undefined) {
                            objDiv.scrollTop = divMessage.offsetTop - 100;
                        }  
                    }
                    
            }
        }
    }

    function checkMessageForExport(message_id) {
        
        let arr = ListMessages.slice()
        arr.forEach((message) => {
            if (message.id == message_id) {
                message.ischeck = !message.ischeck
                console.log('message.ischeck', message.ischeck)
            }
        })
        setListMessage(arr)
    }

    const renderedListMessages = ListMessages.slice(leftInd, rightInd).map(message => {
        return <MessageItem 
            key={message.id}
            id={message.id} 
            text={message.text}
            username={message.username}
            isOwn={message.id_user==props.UserId}
            filepath={message.filepath}
            filetype={message.filetype}
            filename={message.filename}
            idanswermessage={message.idanswermessage}
            isfav={message.isfav}
            ischeck={message.ischeck}
            url={props.url}
            urlh={props.urlh}
            setInpFocus={setInpFocus}
            setFavorite={setFavorite}
            infoanswer={message.infoanswer}
            textDate={message.date.textDate}
            textTime={message.date.textTime}
            isFranch={props.isFranch}
            isModer={props.isModer}
            isMobile={isMobile}
            setSrcPreShowMessage={props.setSrcPreShowMessage}
            setTypePreShowMessage={props.setTypePreShowMessage}
            setPreShowMessage={props.setPreShowMessage}
            setNameDownloadImg={props.setNameDownloadImg}
            preShowMessage={preShowMessage}
            removeEvListScroll={removeEvListScroll}
            handleUsersIsRead={handleUsersIsRead}
            findAnswerMessage={findAnswerMessage}
            showCheckBoxMessage={showCheckBoxMessage}
            checkMessageForExport={checkMessageForExport}
            // files={message.files}
          />
          
    })

    const renderedListUsersIsRead = ListUsersIsRead.map(user => {
    return (
        <div className='people'>
            {user.username}
        </div>
    )
    })
    // const handleClose = () => setShowReg(false);

    function displayNotification() {
        if (Notification.permission == 'granted') {
          navigator.serviceWorker.getRegistration().then(function(reg) {
            reg.showNotification('New message chat');
          });
        }
      }
    
    function handleChat() {
        // console.log('handlechat')
        sendChat(props.UserId, props.idProject, function(data) {
            newMessage(data['messages'])
            // console.log('messages', data['messages'])
            setContentChat(data['messages'], data['idMessageNotIsRead'])
            var objDiv = document.getElementById("chat");
            // console.log('addEventScroll Listener')
            if (objDiv != null && objDiv != undefined) {
                // objDiv.addEventListener('scroll', handleScroll)
            }
        })

    }

    function handleUsersIsRead(id_message) {
        console.log('sendUsersIsRead', props.UserId, id_message)
        sendUsersIsRead(props.UserId, id_message, function(data) {
            setShowUsersIsRead(true)
            setListUsersIsRead(data.usersisread)
            console.log('data.usersisread', data.usersisread)
        })

    }

    function removeEvListScroll() {
        // console.log('removeEvListScroll')
        var objDiv = document.getElementById("chat");
        objDiv.removeEventListener('scroll', handleScroll)
    }

    function handleMessage() {
        let date = dateMessage()
        let idanswer = -1
        if (showAnswerMessage) {
            idanswer = IdAnswer
        }
        sendMessage(props.UserId, Message, idanswer, date, props.idProject, props.UserName, props.ChatName, function(data) {
            // console.log(data.res)
        })
        var objDiv = document.getElementById("chat");
        objDiv.scrollTop = objDiv.scrollHeight
    }

    function handleClickSendMessage () {
        // console.log('send message')
        if (files_input.length === 0 ) {
            // console.log('send by socket')
            handleMessage()
            setShowAnswerMessage(false)
        }
        else {
            let imgchat
            let formData = new FormData();
            for (let i = 0; i < files_input.length; i++) {
                formData.append('message_files', files_input[i]['file']);
            }                                   
            
            // formData.append("filetype", imgchat.type);
            formData.append("id_user", props.UserId);
            formData.append("username", props.UserName);
            formData.append("id_project", props.idProject);
            formData.append("text", Message);
            if (showAnswerMessage) {
                formData.append("idanswer", IdAnswer);    
            }
            else {
                formData.append("idanswer", -1);
            }                                
            formData.append("date", dateMessage())
// fetch new message
            // fetch(props.urlh + 'file', {method: "POST", mode: 'no-cors', body: formData})
            // .then((data) => {
            //     console.log('data', data);
            //   });

            xhr.open('POST', props.urlh + 'file')
            // xhr.setRequestHeader('Access-Control-Allow-Origin', 'http://localhost:5000/file')
            xhr.upload.addEventListener('progress', function(e) {
                // upload progress as percentage
                let percent_completed = (e.loaded / e.total)*100;
                // console.log(percent_completed);
                setPercentCompleted(Math.round(percent_completed))
            });
            xhr.addEventListener('load', function(e) {
                // console.log(xhr.status);
                // console.log(xhr.response);
                setContentMain('messages')
                setShowMessageProcess(false)
                setPercentCompleted(0)
                
                setScroll()
            });
            xhr.addEventListener('abort', event => {
                setContentMain('messages')
                setShowMessageProcess(false)
                setPercentCompleted(0)
                alert('Отправка отменена')
            })
            setShowMessageProcess(true)
            xhr.send(formData)
            setXHR(xhr)
        }
        
        setMessage('')
        setFiles_input([])
        
        setShowAnswerMessage(false)
    }

    function handleScroll () {
        // console.log('handleScroll')
        let currentTick = new Date().getTime() / 1000
        if  (currentTick - tick > 0.03) {
            var objDiv = document.getElementById("chat");
            // console.log('objDiv.scrollTop', objDiv.scrollHeight, objDiv.scrollTop, left_ind)
            if (objDiv.scrollTop < 200 && left_ind != 0) {
                if (left_ind > 19) {
                    setLeftInd(left_ind - 20)
                    left_ind -= 20
                } 
                else {
                    setLeftInd(0)
                    left_ind = 0
                }
                // console.log('leftInd', left_ind)
            }
            
            else if (objDiv.scrollTop > objDiv.scrollHeight - 200 && rightInd != 0 ) {

            }
            
            // console.log('tick', tick, currentTick)
            tick = new Date().getTime() / 1000
        }
        
      }

    const handleSocketOn = (data) => {
        console.log('message from server', data);
        if (data.type === 'newmsgmoder') {
            dispatch(setArrivedNewModerMessage(true))
            dispatch(setArrivedNewMsgChatName(data.chatname))    
        }
        else if (data.type === 'newmsg') {
            handleChat()
        }
        
    }

    useEffect(() => {
        console.log('subscribe socket', props.UserId.toString())
        socket.on(props.UserId.toString(), handleSocketOn);
        return () => {
            socket.removeAllListeners(props.UserId.toString());
        }
    }, []);

    useEffect(() => {
            // console.log('socketon message')
            // console.log('emit chat', props.idProject)
            handleChat()

            if ((!props.isFranch || props.isModer) && !isMobile) {
                var elem = document.getElementById('inputmessage');

                // Execute a function when the user presses a key on the keyboard
                elem.addEventListener("keypress", function(event) {
                // If the user presses the "Enter" key on the keyboard
                    if (event.key === "Enter") {
                        // Cancel the default action, if needed
                        event.preventDefault();
                        // Trigger the button element with a click
                        document.getElementById("btnsend").click();
                        setMessage('')
                        // console.log('enter', Message)
                    }
                });
                elem.addEventListener("keydown", function(event) {
                        if (event.key == 'ArrowDown') {
                            event.preventDefault();
                            let inp = document.getElementById('inputmessage')
                            setMessage(inp.value + '\n')
                            // console.log('ArrowDown', event.target.value)
                        }
                    });
            } 
            
          
            return () => {
                socket.off('newmessagechat')
            }
        
      }, [Socket])

    useEffect(() => {
        if (!preShowMessage) {
            // handleChat()
            var objDiv = document.getElementById("chat");
            // console.log('addEventScroll Listener')
            if (objDiv != null && objDiv != undefined) {
                // objDiv.addEventListener('scroll', handleScroll)
            }
        }
        
    }, [preShowMessage])

    useEffect(() => {
        document.onpaste = function(event)
            {
                var items = event.clipboardData.items;
                // console.log(JSON.stringify(items)); // will give you the mime types
                var blob = items[0].getAsFile();
                var reader = new FileReader();
                reader.onload = function(event)
                {
                    // console.log('event.target.result')
                    let files_arr = files_input.slice() 
                    let ind = files_arr.length
                    // var file = new File([blob], "name.png");
                    files_arr.push({'ind': ind, 'file': blob});
                    setSelectFile(files_arr[0]['file'])
                    setFiles_input(files_arr)
                    setContentMain('files')
                    
                }; // data url  
                if (blob !== null && blob != undefined) {
                    reader.readAsDataURL(blob); 
                }
            }
    })

    useEffect(() => {
        if (TextFind != '' && TextFind != undefined && TextFind != ' ') {
            findText(TextFind)
        }
        
    }, [TextFind])

    useEffect(() => {
        document.addEventListener('keydown', (event) => {
            if (event.key == 'Escape') {
                setShowAnswerMessage(false)
                // console.log('esc')
            }           
          });
    })

    useEffect(() => {
        if (ShowMobileMenu) {
            setShowFind(false)
        }
    }, [ShowMobileMenu])

    useEffect(() => {
        // let id_mess_show = -1
        // if (props.idMessageShow != -1) {
        //     id_mess_show = props.idMessageShow
        // }
        // else if (id_message_not_is_read != -1) {
        //     id_mess_show = id_message_not_is_read
        // }

        // if (id_mess_show == -1) {
        //     console.log('setscroll effect')
        //     setScroll()
        // }
        // else if (rightInd - leftInd < 20) {
        //     if (leftInd - 20 > 0) {
        //         setLeftInd(leftInd - 20)
        //     } else {
        //         setLeftInd(0)
        //     }
        // }
        // console.log('id_message_not_is_read', id_message_not_is_read)
        if (rightInd != 0) {
            if (id_message_not_is_read == -1) {
                setTimeout(setScroll, 500)
            }
        }
        if (leftInd != 0) {
            if (id_message_not_is_read != -1) {
                let messageElement = document.getElementById(id_message_not_is_read.toString() + 'f')
                if (messageElement !== null) {
                    console.log('messageElement.scrollTop', messageElement.offsetTop)
                    let elementChat = document.getElementById('chat')
                    elementChat.scrollTop = messageElement.offsetTop
                }
                
            }
        }
        var objDiv = document.getElementById("chat");
        // console.log('addEventScroll Listener')
        if (objDiv != null && objDiv != undefined) {
            objDiv.addEventListener('scroll', handleScroll)
        }
        
        
    }, [rightInd])

    

    return (
        <>
            {ShowUsersIsRead ? (
                <div className={isMobile ? 
                                'position-absolute top-50 start-50 translate-middle modal-projects border-black zi-2':
                                'position-absolute top-50 start-50 translate-middle modal-projectsD border-black'}>
                    <button 
                        className='btn btn-secondary btn-sm'
                        onClick={() => {
                        setShowUsersIsRead(false)
                        }}
                    >
                        {'Закрыть'}
                    </button>
                    <div >
                        {renderedListUsersIsRead}
                    </div>
                </div>
            ): null}
            
            {/* <div>{tick}</div> */}
            {showMessageProcess ? (
                <>
                    <div className='position-absolute top-50 start-50 translate-middle'>
                        <p className='fs-2v text-color-red'>{'Данные отправляются. Не закрывайте вкладку и браузер.'}</p>
                        
                    </div>
                </>
            ):null}

            {ShowMobileMenu ? (
                <>
                    <div className='mobile-menu bg-lightblack p-2 zi-2'>
                        <div onClick={() => {
                            setShowMobileMenu(false)
                            setShowFind(true)
                        }}>
                            <p className='text-color-white text-start'>{'Поиск'}</p>
                        </div>
                        <div onClick={() => {
                            if (showCheckBoxMessage) {
                                prepareListRowForExport()
                                setContentMain('export')
                            }
                            setShowCheckBoxMessage(true)
                        }}>
                            <p className='text-color-white text-start'>{'Экспорт'}</p> 
                        </div>                   
                    </div>
                </>
            ):null}     

            {ShowFind ? (
                <>
                    <div className='mobile-menu bg-lightblack p-2 w-50'>
                        <input 
                            class='form-control ' 
                            value={TextFind} 
                            placeholder="Поиск" 
                            onChange={(e) => setTextFind(e.target.value)}
                        />                       
                    </div>
                </>
            ):null}     
            
            {contentMain == 'export' ? (
                <>
                    <ExportChat isMobile={isMobile} listRows={listRowsForExport} urlh={props.urlh} UserId={props.UserId} ref={(response) => (componentRef = response)}/>
                    <ReactToPrint
                        content={() => componentRef}
                        trigger={() => <button className="btn btn-dark">Print to PDF</button>}
                    />
                    <button className="btn btn-outline-dark ms-2" onClick={() => {
                        setShowCheckBoxMessage(false)
                        setContentMain('messages')
                    }}>
                        {'Назад'}
                    </button>
                </>
            ): null}

            {contentMain == 'messages'  ? 
            (
                <>
                    
                    <div id='chat' data-bs-spy="scroll" data-bs-offset="300" className={isMobile ? styles.bgbM: styles.bgb}>
                    {/* <div>i{leftInd}</div>
                    <div>i{rightInd}</div> */}
                    {/* <div scroll="yes" className={styles.bgb}> */}
                        <div  className=''>
                            {renderedListMessages}
                        </div>
                    </div>

                    {showAnswerMessage && !preShowMessage  ? (
                        <div className='h-1 d-flex align-items-end'>
                            <div className={isMobile ? 
                                    'row bg-answermessagesend m5v w-75 rounded-3 align-bottom border-black-1 zi-2':
                                    'row bg-answermessagesend m5v w-25 rounded-3 align-bottom border-black-1 zi-2'}>
                                <div className='col-6'>
                                    <p className='text-start text-secondary m-1 fs-10'>
                                        {usernameAM}
                                    </p>
                                    <div className='h-5 text-start m-1 text-wrap text-break overflow-hidden fs-12'>
                                        {textAM}
                                    </div>  
                                </div>
                                {filepathAM != null &&  filepathAM != '' ? (
                                    <div className={'d-flex justify-content-start col-6'}>
                                        {IMGFILES.includes(filetypeAM) ? (
                                            <>
                                                <a  
                                                // href={props.urlh + 'static/img/' + filepath} 
                                                className='d-flex justify-content-start'
                                                download 
                                                // onClick={downloadImage}
                                                >
                                                <img className='m-1 rounded-3 w-100 h-100' src={props.urlh + 'static/uploads/' + filepathAM} />
                                                </a>
                                            </>
                                            ): null}
                                            {filetypeAM == 'mp4' || filetypeAM == 'MOV'? (
                                            <video className='m-1 rounded-3 w-90' controls="controls" >
                                                <source src={props.urlh + 'static/uploads/' + filepathAM} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                                            </video>
                                            ): null}
                                            {filetypeAM == 'ogg' ? (
                                                <>
                                                <audio
                                                    controls
                                                    src={props.urlh + 'static/uploads/' + filepathAM}>
                                                        Your browser does not support the
                                                        <code>audio</code> element.
                                                </audio>
                                                </>
                                            ): null}
                                            {filetypeAM != 'mp4' && !IMGFILES.includes(filetypeAM) && filetypeAM != 'ogg'? (
                                            <a  
                                                // href={props.urlh + 'static/img/' + filepath} 
                                                download 
                                                // onClick={downloadImage}
                                            >
                                                <div className='m-1 rounded-3 anim'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                                    </svg>
                                                </div>
                                            </a>
                                            ): null}
                                    </div>
                                ): null}   
                            </div> 
                        </div>
                    ): 
                    (
                        <div className='h-1 '>
                        </div>
                    )}

                    
                </>
            ): null}

            {contentMain == 'files' ? (
                <>
                    <div className={styles.fileimg}>
                        {IMGFILES.includes(selectFile.name.slice(-3)) ? (
                            <img className='mw-100 mh-100' src={URL.createObjectURL(selectFile)}/>
                        ): null}

                        {selectFile.name.slice(-3) == 'mp4' ? (
                            <video className='mw-100 mh-100' controls="controls" >
                                <source src={URL.createObjectURL(selectFile)} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                            </video>
                        ): null}

                        {selectFile.name.slice(-3) == 'pdf' || selectFile.name.slice(-3) == 'txt' ? (
                            <object height="100%"
                                width="100%" className='mw-100 mh-100' type="text/plain" data={URL.createObjectURL(selectFile)}
                            />
                        ): null}

                        {selectFile.name.slice(-3) == 'ogg' ? (
                            <audio
                                controls
                                src={URL.createObjectURL(selectFile)}>
                                    Your browser does not support the
                                    <code>audio</code> element.
                            </audio>
                        ): null}

                    </div>
                    <div className='row mb-4 mx-2 mt-2'>
                        {files_input.map(f => {
                            return <FileItem 
                                    key={f['ind']} 
                                    ind={f['ind']}
                                    indSelectFile={indSelectFile}
                                    fileObj={f['file']} 
                                    setSelectFile={setSelectFile}
                                    setIndSelectFile={setIndSelectFile}
                                    delFileMessage={delFileMessage}
                                    isMobile={isMobile}
                                />
                        })}
                    </div>
                </>
            ): null}

            {contentMain == 'camera' ? (
                <MyCamera 
                    setContentMain={setContentMain} 
                    setFiles_input={setFiles_input} 
                    setSelectFile={setSelectFile}
                    UserId={props.UserId}
                />
            ): null}

            {contentMain == 'messages' || contentMain == 'files' ? (
                <>
                    <div className="row m5v">

                        {(!props.isFranch || props.isModer) && !preShowMessage ? (
                            <>
                                <div className={isMobile ? 'col-7 p-0 m-0': "col-6 p-0 m-0"}>
                                    <TextareaAutosize  
                                        id='inputmessage' 
                                        type="text" 
                                        className="form-control nores" 
                                        value={Message} 
                                        placeholder="Сообщение" 
                                        onChange={(e) => {
                                                if (e.target.value.trim().length == 0 && showSendButton) {
                                                    setShowSendButton(false)
                                                }
                                                else if (!showSendButton) {
                                                    setShowSendButton(true)
                                                }
                                                setMessage(e.target.value)
                                            }
                                        }
                                    />
                                </div>
                                
                                {/* add file */}
                                <div className='col-1 img-upload'>
                                    <label for="myfile">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-paperclip t-sc" viewBox="0 0 16 16">
                                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                                        </svg>
                                    </label>
                                    <input id='myfile' name='myfile' type="file" multiple 
                                        onChange={() => {
                                            let files = document.getElementById("myfile")
                                            
                                            let files_arr = files_input.slice() 
                                            let ind = files_arr.length 

                                            //test quantity and size of files

                                            if (files_arr.length + files.files.length > 12) {
                                                alert('Количество файлов должно быть не более 12')
                                            } 
                                            else {
                                                let sizeIsCorrect = true
                                                for (let i = 0; i < files.files.length; i++) {
                                                    if (files.files[i].size > 1073741824) {
                                                        sizeIsCorrect = false
                                                        break
                                                    }
                                                }

                                                if (sizeIsCorrect) {
                                                    for (let i = 0; i < files.files.length; i++) {
                                                        // console.log('size', files.files[i].size)
                                                        files_arr.push({'ind': ind, 'file': files.files[i]});
                                                        ind += 1
                                                    }
                                                    setFiles_input(files_arr)
                                                    setSelectFile(files_arr[indSelectFile]['file'])
                                                    // console.log('addfile true')
                                                    setContentMain('files')
                                                }
                                                else {
                                                    alert('Размер одного файла не должен превышать 1Гб')
                                                }
                                                
                                            }                     
                                            
                                        }
                                    }/>
                                </div>

                                {/* microphone */}
                                <div className='col-1'>
                                    {/* <AudioRec setdataRec={setdataRec}/> */}
                                    {isAudio ? (
                                        <AudioRec 
                                            urlh={props.urlh} 
                                            UserId={props.UserId} 
                                            Message={Message} 
                                            IdAnswer={IdAnswer} 
                                            setFiles_input={setFiles_input}
                                            files_input={files_input}
                                            setContentMain={setContentMain}
                                            setSelectFile={setSelectFile}
                                            setIsAudio={setIsAudio}
                                        />
                                    ): (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mic t-sc link" viewBox="0 0 16 16"
                                            onClick={() => {
                                                setIsAudio(true)
                                            }}
                                        >
                                            <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
                                            <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
                                        </svg>
                                    )}
                                </div>
                                
                                {/* camera */}
                                {isMobile && files_input.length == 0 ? (
                                    <div className='col-1' onClick={() => {
                                        setContentMain('camera')
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera" viewBox="0 0 16 16">
                                            <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                                            <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                        </svg>
                                    </div>
                                ): null}
                                
                                
                                {/* send */}
                                {!isMobile ? (
                                    <div className='col-1'>
                                        {showMessageProcess ? (
                                            <button className='btn btn-danger zi-2' onClick={() => {
                                                // console.log('abort')
                                                XHR.abort()
                                            }}>
                                                {'Отменить'}
                                            </button>
                                        ): 
                                        (
                                            <button id='btnsend' className={isMobile ? '': 'btn btn-secondary'} 
                                                onClick={() => {
                                                    handleClickSendMessage()
                                                }}>
                                                {!isMobile  ?  'Отправить': (
                                                    showSendButton ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send t-sc" viewBox="0 0 16 16">
                                                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                                                        </svg>
                                                    ): null
                                                )}
                                            </button>
                                        )
                                    }
                                    </div>
                                ): (
                                    <>
                                        {showSendButton || contentMain == 'files' ? (
                                            <>
                                                <div className='col-1'>
                                                    {showMessageProcess ? (
                                                        <div onClick={() => {
                                                            // console.log('abort')
                                                            XHR.abort()
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" color='red' width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                                            </svg>
                                                        </div>
                                                    ): 
                                                    (
                                                        <div onClick={() => {
                                                                handleClickSendMessage()
                                                            }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send t-sc" viewBox="0 0 16 16">
                                                                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                                                                </svg>
                                                        </div>
                                                    )
                                                        }
                                                </div>
                                            </>
                                        ): null}
                                    </>
                                )}
                            </>
                        ): null}

                        {isMobile ? (
                                <>
                                    {contentMain == 'files' ? (
                                        <>
                                            <div className='col-1 p-0 m-0 d-flex justify-content-start'>
                                                {percentCompleted}
                                            </div>
                                        </>
                                    ): null}
                                </>
                            ): (
                                <div className='col-1'>
                                    {contentMain == 'files' ? (
                                        <>
                                            <div>
                                                {percentCompleted}
                                            </div>
                                        </>
                                    ): null}
                                </div>
                            )
                        }
                        
                    </div>

                    {/* find & export */}
                    {!isMobile ? (
                        <div className='row m5v mt-1 d-flex align-items-center'>    
                                <div className='col-4 p-0 m-0 '>
                                    {contentMain == 'messages' ? (
                                        <input 
                                            class='form-control inputfind' 
                                            value={TextFind} 
                                            placeholder="Поиск" 
                                            onChange={(e) => setTextFind(e.target.value)}
                                        />
                                    ): null}
                                </div>
                                <div className='col-2 d-flex justify-content-end p-0'>
                                    {contentMain == 'messages' && showCheckBoxMessage == false ? (
                                        <button className="btn btn-dark" onClick={() => {
                                            // setContentMain('export')
                                            setShowCheckBoxMessage(true)
                                        }}>
                                            {'Экспорт'}
                                        </button>
                                    ): (
                                        <button className="btn btn-dark" onClick={() => {
                                            setCheckMessagesFalse()
                                            setShowCheckBoxMessage(false)
                                        }}>
                                            {'Отмена'}
                                        </button>
                                    )}
                                    
                                </div> 
                                <div className='col-1 p-0'>
                                    {contentMain == 'messages' && showCheckBoxMessage == true ? (
                                    <button className="btn btn-dark" onClick={() => {
                                        prepareListRowForExport()
                                        setContentMain('export')
                                    }}>
                                        {'Экспорт'}
                                    </button>): null}
                                </div>   
                                {showCheckBoxMessage == true ? (
                                    <>
                                        <div className='col-1 p-0'>
                                            <button className="btn btn-light" onClick={() => {
                                                setCheckMessagesTrue()
                                            }}>
                                                {'Выбрать все'}
                                            </button>
                                        </div>  
                                        <div className='col-1 p-0'> 
                                            <button className="btn btn-light" onClick={() => {
                                                setCheckMessagesFalse()
                                            }}>
                                                {'Снять все'}
                                            </button>
                                        </div>
                                    </>
                                ): null} 
                        </div>
                    ): null}
                </>
            ): null}
            
        </>
    )
}