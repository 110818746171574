import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import {doLoginAsync, selectIsLogin, selectUserId, doUnLoginAsync, doRegAsync} from './loginSlice'


import { sendLogin, sendMail } from '../lib/funcs'
import { useTelegram } from '../hooks/useTelegram'



export function Login(props) {
    const { user_id } = useTelegram()

    const [Name, setName] = useState('')
    const [names, setNames] = useState([])
    const [Password, setPassword] = useState('')
    const [Socket, setSocket] = useState(null)
    // const [isLogin, setIsLogin] = useState(false)
    let isLogin = false
    
    const dispatch = useDispatch();


    function handleLogin(...args) {
        // localStorage.setItem('last_name', '')
        console.log('send login', args)
        let username = Name
        let password = Password
        if (args.length > 0) {
            username = args[0].name
            password = args[0].password
        }
        else {
            let names = localStorage.getItem('names')
            let arr_names = []
            if (names != null) {
                arr_names = names.split('*')
                if (!arr_names.includes(Name)) {
                    arr_names.push(Name)
                }
            }
            else {
                
                arr_names.push(Name)
            }
            
            localStorage.setItem('names', arr_names.join('*'))

            localStorage.setItem(Name, Password)
            localStorage.setItem('last_name', Name)

        }
        
        let t = localStorage.getItem('token')
        console.log('t', t)
        if (t == null) {
            t = Math.random().toString(36).slice(2, 9)
            
        }

        console.log('sendLogin', user_id)
        sendLogin(username, password, t, user_id, function(data) {
            console.log(data)
            if (data['res']) {
                localStorage.setItem('token', t)
                isLogin = true
                props.setUserId(data['id_user'])
                props.setUserName(data['username'])
                props.setIsModer(data['ismoder'])
                props.setIsFranch(data['isfranch'])
                props.setIsAdm(data['isadm'])
                props.setIsLogin(true)
                if (data['isadm']) {
                    props.setPage('franchs')
                }
                else {
                    props.setPage('projects')
                }
            } 
            else {
                alert('Доступ запрещен')
            }
        })


        
    }

    useEffect(() => {
            let names = localStorage.getItem('names')
            if (names != null) {
                let arr_names = names.split('*')
                let last_name = localStorage.getItem('last_name')
                console.log('last_name', last_name)
                if (last_name != '' && last_name != undefined && !props.isLogin) {
                    for (let i=0; i<arr_names.length; i++) {
                        if (arr_names[i] == last_name) {
                            let pass = localStorage.getItem(last_name)
                            console.log('islogin', props.isLogin)
                            
                            handleLogin({'name': last_name, 'password': pass})
                            break
                        }
                    }
                }
                else {
                    
                    props.setIsLogin(false)
                }
                setNames(arr_names)
                console.log('arrnames', arr_names)
            }
            console.log('names', names)
        
        
    }, [])
    return (
        <>
            <div className="p-5">
                <div className="d-flex justify-content-center">
                    <div className={props.isMobile ? ('w-100'): ("w-25")}>
                        <h3 className="">{'У вас есть аккаунт?'}</h3>
                        <div className="m-3">
                            <input class='form-control' value={Name} placeholder="Имя" onChange={(e) => setName(e.target.value)}/>
                        </div>

                        <div className="m-3">
                            <input id="password-field" type="password" className="form-control" value={Password} placeholder="Пароль" onChange={(e) => setPassword(e.target.value)}/>
                            
                        </div>

                        <div className="">
                            <button type="submit" className="btn btn-secondary m-3" 
                                onClick={() => {
                                    handleLogin()
                                }}
                            >
                                {'Войти'}
                            </button>
                        </div>

                        {
                            names.map((name, index) => {
                                return (
                                    <div key={index} className='row'>

                                        <div className='link-cursor col-10' 
                                            onClick={() => {
                                                let pass = localStorage.getItem(name)
                                                setPassword(pass)
                                                setName(name)
                                            }}>
                                            <p>{name}</p>
                                        </div>
                                        <div className='col-1 link'
                                            onClick={() => {
                                                localStorage.removeItem(name)
                                                let arr = []
                                                for (let i=0; i<names.length; i++) {
                                                    if (names[i] != name) {
                                                        arr.push(names[i])
                                                    }
                                                }
                                                localStorage.setItem('names', arr.join('*'))
                                                setNames(arr)
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle link-color-r" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </div>
                                    </div>

                                )
                            })
                        }
                        {/* <div className="">
                            <button className="form-control btn btn-outline-secondary px-3" 
                            onClick={() => {props.setPage('registration')}}>{'Зарегестрироваться'}</button>
                        </div> */}
                        {/* <button onClick={() => {
                            testMail()
                        }}>
                            SENDMAIL
                        </button> */}
                    </div>
                </div>
            </div>
            
        </>
    )
}