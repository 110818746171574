import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch} from 'react-redux'

const initialState = {
    id: '',
    IsLogin: false,
    IsReg: false,
};


export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    },

});

// export const { } = loginSlice.actions

export default chatSlice.reducer;
