import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'
import {parseurl} from '../lib/funcs'
import FileItem from '../lib/components'

const IMGFILES = ['png', 'jpg', 'peg', 'jpeg', 'JPG']

export default function MessageItem(props) {
  const text = props.text
  const id = props.id
  const username = props.username
  const filepath = props.filepath
  const filetype = props.filetype
  const filename = props.filename
  const infoanswer = props.infoanswer
  const id_user = props.id_user
  const textDate = props.textDate
  const textTime = props.textTime
  const isMobile = props.isMobile
  const is_read = props.is_read
  const handleDelMessageModer = props.handleDelMessageModer

  
  const [beforText, setBeforText] = useState('')
  const [afterText, setAfterText] = useState('')
  const [urlText, setUrlText] = useState('')

  const [listPartsMessage, setListPartsMessage] = useState([])

  const downloadImage = () => {
    // console.log('saave')
    saveAs(props.urlh + 'static/img/' + filepath)
  }

  const renderedListPartsMessage = listPartsMessage.map(part => {
    return (
      <>
        {part.type == 'text' ? (
          <p className='wa p-0 m-0'>{' ' + part.text + ' '}</p>
        ): (
          <a href={part.text} target="_blank">{part.text}</a>
        )}
      </>
    )
  })

  // const handleScroll = e => {
  //   const list_el = document.getElementById('messages_not_isread')
  //   const el = document.getElementById(id)
  //   console.log('handleScroll')
  //   if (el !== null) {
  //     console.log('s', el.offsetTop, list_el.scrollTop)
  //     if (el.offsetTop - list_el.scrollTop < 700) {
  //       console.log('datasrc - src')
  //       el.src = el.dataset.src
  //     }
  //   }
  // }

  // useEffect(() => {
  //   handleScroll()
  //   const list_el = document.getElementById('messages_not_isread')
  //   console.log('addEventListener')
  //   list_el.addEventListener('scroll', handleScroll)
  //   return () => {
  //     list_el.removeEventListener('scroll', handleScroll)
  //   }
  // })

  useEffect(() => {
    if (text != null && text != undefined && text != '')
    {
      let dataUrl = parseurl(text)
      setListPartsMessage(dataUrl)
      // setBeforText(dataUrl['textbefore'])
      // setAfterText(dataUrl['textafter'])
      // setUrlText(dataUrl['url'])
    }
  }, [])
  
  return (
    <>
      <div className={''}>
        <div className={isMobile ? 
            (is_read ? 
            'border border-success m-1 w-75 rounded-3 bg-grey': 'border border-success m-1 w-75 rounded-3 bg-messageitem'):
            (is_read ? 
              'border border-success m-1 w-35 rounded-3 bg-grey': 'border border-success m-1 w-35 rounded-3 bg-messageitem')  
          }
        >
          
          <div className='text-start  m-1 fs-10'>
            {username}
          </div>

          {infoanswer != '' ? (
            <>
              <div className='bg-answermessage rounded-3 align-bottom m-1'>
                  <div className='text-start text-secondary m-1 fs-10'>
                      {infoanswer['username']}
                  </div>
                  <div className='text-start m-1 text-wrap text-break  fs-12 text-color-white fw-bold'>
                    {infoanswer['text']}
                  </div>  
                  {infoanswer['filepath'] != null ? (
                        <div className='d-flex justify-content-start h-15'>
                            {IMGFILES.includes(infoanswer['filetype']) ? (
                                <>
                                    <a  
                                    // href={props.urlh + 'static/img/' + filepath} 
                                    className='d-flex justify-content-start'
                                    download 
                                    // onClick={downloadImage}
                                    >
                                    <img className='m-1 rounded-3 w-75' src={props.urlh + 'static/img/' + infoanswer['filepath']} />
                                    </a>
                                </>
                                ): null}
                                {infoanswer['filetype'] == 'video/mp4' ? (
                                <video className='m-1 rounded-3' controls="controls" >
                                    <source src={props.urlh + 'static/img/' + infoanswer['filepath']} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                                </video>
                                ): null}
                                {infoanswer['filetype'] != 'mp4' && !IMGFILES.includes(infoanswer['filetype']) ? (
                                <a  
                                    // href={props.urlh + 'static/img/' + filepath} 
                                    download 
                                    onClick={downloadImage}
                                >
                                    <div className='m-1 rounded-3 anim'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                            <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                        </svg>
                                    </div>
                                </a>
                                ): null}
                        </div>
                    ): null}    
              </div>  
            </>
          ): null}

          {filepath != '' &&  filepath != null ? (
            <>
                <FileItem 
                  setPreShowMessage={props.setPreShowMessage} 
                  setSrcPreShowMessage={props.setSrcPreShowMessage} 
                  setTypePreShowMessage={props.setTypePreShowMessage}
                  setNameDownloadImg={props.setNameDownloadImg}
                  urlh={props.urlh} 
                  filename={filename} 
                  filetype={filetype} 
                  filepath={filepath} 
                  id={id}
                  isMobile={isMobile}
                />
            </>
              ): null} 

          <div className='d-flex justify-content-start'>
            <div className='m-1 w-100 '>
                
              <div className='text-start m-1 text-wrap text-break'>
                  {/* {beforText + ' '}
                  <a href={urlText} target="_blank">{urlText}</a>
                  {' ' + afterText} */}
                  {/* <p className='wa p-0 m-0'>{beforText + ' '}</p>
                  <a href={urlText} target="_blank">{urlText}</a>
                  <p className='wa p-0 m-0'>{' ' + afterText}</p> */}
                  {renderedListPartsMessage}
              </div>
            </div>
            
          </div>
          <div className='p-0 m-0 d-flex justify-content-end me-1'>
            {!isMobile ? (
              <div className='p-0 m-0 me-3 btn btn-secondary btn-sm w-4'
                onClick={() => {
                  handleDelMessageModer(id)
                }}
              >
                {'Удалить'}
              </div>):
              (
                <>
                  <svg onClick={() => {
                  handleDelMessageModer(id)
                }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                  </svg>
                </>
              )
            }
                
                  
          </div>
            
          <div className='d-flex justify-content-between'>
            <p className='ms-1 mb-1 fs-10'>{textTime}</p>
            <p className='me-1 mb-1 fs-10'>{textDate}</p>
          </div>
        </div>
        
      </div>
    </>
  )
}