import React, { useEffect, useState } from 'react'

export default function NewProject(props) {
    const setShowFormNewProject = props.setShowFormNewProject
    const [nameProject, setNameProject] = useState('')
    return (
        <>
            <div className={props.isMobile ? 
                'position-absolute top-50 start-50 translate-middle w-75 h-25 form-border bg-grey':
                "position-absolute top-50 start-50 translate-middle w-25 h-25 form-border bg-grey"}>
                <div className='d-flex justify-content-center flex-column p-2'>
                    <p>{'Новый проект'}</p>
                    <input className='bg-white m-2' placeholder="Название" value={nameProject} 
                        onChange={(e) => {setNameProject(e.target.value)}}>
                    </input>

                    <div className='w-100 row d-flex justify-content-center m-2'>
                        <button className='col-4 m-1' onClick={() => {
                                props.handleNewProject(nameProject)
                                setShowFormNewProject(false)
                            }}>
                            {'Создать'}
                        </button>
                        <button className='col-4 m-1' onClick={() => {
                            setShowFormNewProject(false)
                        }}>
                            {'Отмена'}
                        </button>
                    </div>
                </div>
                
                    
            </div>
        </>
    )
}