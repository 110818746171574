import { createSlice } from '@reduxjs/toolkit';



const initialState = {
    id: '',
    IsLogin: false,
    IsReg: false,
};


export const messagemoderSlice = createSlice({
  name: 'messagemoder',
  initialState,
  reducers: {
    setId: (state, action) => {
      console.log('000')
      state.id = action.id;
    },
  },

});

export const { setId } = messagemoderSlice.actions;

export const selectId = (state) => state.messagemoder.id;

export default messagemoderSlice.reducer;
