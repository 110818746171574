import React, { useEffect, useState } from 'react'
import { saveAs } from 'file-saver'

const IMGFILES = ['png', 'jpg', 'jpeg', 'bmp']

export default function FileItem(props) {
    const filepath = props.filepath
    const filetype = props.filetype
    const filename = props.filename
    const id = props.id
    const isMobile = props.isMobile
    const setSrcPreShowMessage = props.setSrcPreShowMessage
    const setTypePreShowMessage = props.setTypePreShowMessage
    const setPreShowMessage = props.setPreShowMessage
    const PDFURL = props.urlh + 'static/uploads/' + props.filepath

    const downloadImage = () => {
        console.log('saeave')
        saveAs(props.urlh + 'static/uploads/' + filepath, filename)
    }

    return (
        <>
            <div className={isMobile ? 'd-flex justify-content-start flex-column conteinerFileM' : 'd-flex justify-content-start flex-column conteinerFile'}>
                {IMGFILES.includes(filetype) ? (
                    <>
                        <a
                            // href={props.urlh + 'static/img/' + filepath} 
                            className='d-flex justify-content-start'
                            download
                            // onClick={downloadImage}>
                            onClick={() => {
                                // props.removeEvListScroll()
                                setPreShowMessage(true)
                                setTypePreShowMessage('img')
                                setSrcPreShowMessage(props.urlh + 'static/uploads/' + filepath)
                                props.setNameDownloadImg(filename)
                            }}
                        >
                            <img id={id} className={isMobile ? 'm-1 rounded-3 text-messageM' : 'm-1 rounded-3 text-message'} src={props.urlh + 'static/uploads/' + filepath} />
                        </a>
                    </>
                ) : null}

                {filetype == 'mp4' || filetype == 'mov' || filetype == 'MOV' ? (
                    <div>
                        <video className='m-1 rounded-3 w-90' controls="controls" >
                            <source id={id} src={props.urlh + 'static/uploads/' + filepath} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                        </video>
                    </div>
                ) : null}

                {filetype === 'pdf' || filetype === 'txt' ? (
                    <div>
                        <div className=' d-flex justify-content-end'>
                            {
                                isMobile ?
                                <a target='_blank' download rel='noreferrer' href={props.urlh + 'static/uploads/' + filepath}>{filename || filepath.slice(filepath.indexOf('/')+1)}</a>:
                                <p className='p-0 m-0 link-item font-s'
                                    onClick={() => {
                                        console.log('kll')
                                        setPreShowMessage(true)
                                        setTypePreShowMessage('pdf')
                                        setSrcPreShowMessage(props.urlh + 'static/uploads/' + filepath)
                                        props.setNameDownloadImg(filename)
                                    }}
                                >
                                    Открыть
                                </p>
                            }
                        </div>
                        <div>
                            {
                                !isMobile && 
                                <iframe
                                    title={filepath}
                                    height="100%"
                                    width="100%"
                                    className='mw-100 mh-100'
                                    src={props.urlh + 'static/uploads/' + filepath}
                                />
                            }
                        </div>
                    </div>
                ) : null}

                {filetype !== 'mp4' && !IMGFILES.includes(filetype) && filetype !== 'ogg' && filetype !== 'pdf' && filetype !== 'txt' ? (
                    <a target='_blank' download rel='noreferrer' href={props.urlh + 'static/uploads/' + filepath}>{filename || filepath.slice(filepath.indexOf('/')+1)}</a>
                ) : null}
                {filetype === 'ogg' || filetype === 'oga' ? (
                    <>
                        <div className='w-50'>
                            <audio
                                className='z-audio'
                                id={id}
                                controls
                                src={props.urlh + 'static/uploads/' + filepath}>
                                Your browser does not support the
                                <code>audio</code> element.
                            </audio>
                        </div>
                    </>
                ) : null}
            </div>

            <a target='_blank' download className='d-flex justify-content-start m-1 fs-12' rel='noreferrer' href={props.urlh + 'static/uploads/' + filepath}>
                {IMGFILES.includes(filetype) || filetype === 'mp4' || filetype === 'ogg' ? filename || 'скачать' : 'скачать'}
            </a>
        </>
    )
}




{/* camera */ }
{/* <div className='col-1 img-upload'>
<svg onClick={() => setShowCamera(true)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera" viewBox="0 0 16 16">
    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
</svg>
</div> */}