import React, { useState, useEffect } from 'react'
import { sendSetLabel } from '../lib/funcs'
import { useDispatch } from 'react-redux'
import { setArrivedNewModerMessage } from '../mainSlice'

export default function ProjectItem(props) {
    const dispatch = useDispatch()
    const project_name = props.project_name
    const id = props.id
    const setSelectIdProject = props.setSelectIdProject
    const statusname = props.statusname
    const color = props.color
    
    const role = props.role
    const setModerName = props.setModerName
    const setProjectName = props.setProjectName
    const setModerId = props.setModerId
    const modername = props.modername
    const id_moder = props.id_moder
    const id_status = props.id_status
    const setStatusId = props.setStatusId
    const q = props.q
    const isMobile = props.isMobile
    const label = props.label
    const id_user = props.id_user
    const new_msg_project_id = props.newMsgProjectId
    const setNewMsgProjectId = props.setNewMsgProjectId

    const [ShowEditLabel, setShowEditLabel] = useState(false)
    const [textLabel, settextLabel] = useState('')

    return (
        <>  
            {
                ShowEditLabel ? (
                    <>
                    <div className='pos-fixed d-flex justify-content-center  top-50 start-50 translate-middle bg-grey rounded zi-2'>
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                            class="bi bi-x-circle m-2 t-sc2 link-item" viewBox="0 0 16 16"
                            onClick={() => {
                                setShowEditLabel(false)
                            }}
                        >
                            
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>

                        <div className='w-100 mt-5'>
                            <div className="m-3">
                                <input id="" type="text" className="form-control" value={textLabel} placeholder="Ярлык" onChange={(e) => settextLabel(e.target.value)}/>
                            </div>
                            <div className="form-element">
                                <button className="btn btn-secondary m-3" 
                                    onClick={() => {
                                        console.log('send set label')
                                        sendSetLabel(id_user, id, textLabel, function(data) {
                                            console.log(data)     
                                            if (data.res) {
                                                props.setProjectLabel(id, textLabel)
                                            }                               
                                        })
                                        setShowEditLabel(false)
                                    }}
                                >
                                    {'Установить ярлык'}
                                </button>
                            </div>
                        </div>
                    </div>
                    </>
                ): null
            }
            <div className={isMobile ? 'text-start  ms-2': 'text-start link-item col-3' }
                    onClick={() => {
                    if (!props.isAdm) {
                        props.handleProjectInfo(id)
                        props.setShowProjectInfo(true)
                        setSelectIdProject(id)
                        setModerName(modername)
                        setModerId(id_moder)
                        setProjectName(project_name)
                        setStatusId(id_status)
                        props.setTabUserModer('users')
                    }
                        
                    }}>
                        <p className='fw-bold'>
                            {project_name}
                        </p>
                        {isMobile ? (
                            <>
                                <div  >
                                    <p style={{backgroundColor: '#' + color, fontSize: 'small', display: 'inline-block'}}>
                                        {statusname}
                                    </p>
                                </div>
                                <div className='font-s'>
                                    {modername}
                                </div>
                            </>
                        ): null}
            </div>
            <div className={isMobile ? 'd-flex align-items-center justify-content-between row my-3 p-2 border-grey-bottom': 'row my-3 py-2 border-grey-bottom'}>
                

                <div className='col-2' onClick={() => {
                    setShowEditLabel(!ShowEditLabel)
                }}>
                    {/* {label} */}
                    {label == '' || label == null ? 
                    (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark link-item" viewBox="0 0 16 16">
                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                        </svg>
                    ): (<p className='p-0 m-0 link-item '>{label}</p> )}
                </div>   

                <div className='col-2'>
                    <div style={new_msg_project_id === id ? {backgroundColor: '#EA1744', borderRadius: '10px'}:{}}>{q}</div>
                </div>
                
                {props.isFranch && !isMobile ? (
                <div className='col-2'>
                    {modername}
                </div>): null}

                {!isMobile ? (
                    <div className='col-1 fs-10'>
                        <p style={{backgroundColor: '#' + color, fontSize: 'small', textAlign: 'start'}}>
                            {statusname}
                        </p>
                    </div>
                ): null}
                
                <div className='col-2 p-0 m-0'>
                    <button className='btn btn-secondary btn-sm' onClick={() => {

                        console.log(999)
                        props.setIdProject(id)
                        props.setPage('chat')
                        props.setChatName(project_name)
                        props.setChatMode(true)
                        setNewMsgProjectId(0)
                        dispatch(setArrivedNewModerMessage(false))
                    }}>
                        {isMobile ? 'В чат': 'Перейти в чат'}
                    </button>
                </div>

                {props.isFranch ? 
                    (
                        <>
                            {isMobile ? (
                                <div className='col-1 p-0' onClick={() => {
                                    if (window.confirm('Вы уверенны?')) {
                                        props.handleDeleteProject(id)
                                    }
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3 t-sc2" viewBox="0 0 16 16">
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                                    </svg>
                                </div>
                            ): (
                                <div className='col-1'>
                                    <button className='btn btn-secondary btn-sm' onClick={() => {
                                        if (window.confirm('Вы уверенны?')) {
                                            props.handleDeleteProject(id)
                                        }
                                    }}>
                                        {'Удалить'}
                                    </button>
                                </div>
                                )
                            }
                            {isMobile ? (
                                <div className='col-1 p-0 d-flex justify-content-start' onClick={() => {
                                    if (window.confirm('Вы уверенны?')) {
                                        props.handleArchiveProject(id)
                                    }
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-zip t-sc2" viewBox="0 0 16 16">
                                        <path d="M6.5 7.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.109 0l-.93-.62a1 1 0 0 1-.415-1.074l.4-1.599V7.5zm2 0h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243V7.5z"/>
                                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm5.5-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9v1H8v1h1v1H8v1h1v1H7.5V5h-1V4h1V3h-1V2h1V1z"/>
                                    </svg>
                                </div>
                            ): (
                                <div className='col-1'>
                                    <button className='btn btn-secondary btn-sm' onClick={() => {
                                        if (window.confirm('Вы уверенны?')) {
                                            props.handleArchiveProject(id)
                                        }
                                    }}>
                                        {'В архив'}
                                    </button>
                                </div>
                                )
                            }
                        </>
                        
                    ): null
                }

            </div>

        </>
    )
}