import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';

import ProjectItem from './ProjectItem'
import NewProject from './newProject'
import NewUser from './newUser'
import UserItem from './UserItem'
import ModerItem from '../moders/ModerItem'
import Status from './Status'
import SelectUser from './selectUser'
import {
    getUserProjects,
    sendNewProject,
    sendDeleteProject,
    sendProjectInfo,
    getFranchModers,
    sendNewUser,
    sendStopUser,
    sendDeleteUser,
    sendAppointModer,
    getStatuses,
    sendSetStatus,
    sendUsers,
    sendAddUserToProject,
    sendTakeOffUserToProject,
    sendProjectsOfUser,
    sendArchiveProject,
    sendProjectsArchive,
    sendActivateProject,
} from '../lib/funcs'
import { useSocket } from '../hooks/useSocket';
import { useDispatch } from 'react-redux';
import { setArrivedNewMessage, setArrivedNewModerMessage, setArrivedNewMsgChatName } from '../mainSlice';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

let list_projects = []
export function ChatProjects(props) {
    const dispatch = useDispatch()
    const { socket } = useSocket()
    const [state, setState] = useState(0)
    const [selectIdProject, setSelectIdProject] = useState(0)
    const [ListProjects, setListProjects] = useState([])
    const [listProject, setListProject] = useState([])
    const [ListModers, setListModers] = useState([])
    const [ListStatuses, setListStatuses] = useState([])
    const [ListUsers, setListUsers] = useState([])
    const [ModerName, setModerName] = useState(null)
    const [ProjectName, setProjectName] = useState(null)
    const [ModerId, setModerId] = useState(null)
    const [StatusId, setStatusId] = useState(null)
    const [ShowFormNewProject, setShowFormNewProject] = useState(false)
    const [ShowFormNewUser, setShowFormNewUser] = useState(false)
    const [ShowListUsers, setShowListUsers] = useState(false)

    const [ShowProjectInfo, setShowProjectInfo] = useState(false)
    const [UsersProject, setUsersProject] = useState([])
    const [ShowSelectUser, setShowSelectUser] = useState(false)
    const [ShowUserInfo, setShowUserInfo] = useState(false)
    const [listProjectsArchive, setListProjectsArchive] = useState([])
    const [showArchive, setShowArchive] = useState(false)

    const [TabUserModer, setTabUserModer] = useState('users')

    const [newMsgProjectId, setNewMsgProjectId] = useState(0);

    function setProjectLabel(id, textLabel) {
        console.log('setProjectLabel', id, textLabel)
        let arr = ListProjects.slice()
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id == id) {
                arr[i]['label'] = textLabel
                break
            }
        }
        setListProjects(arr)
        list_projects = arr
    }

    const renderListProjects = ListProjects.map(project => {
        return <ProjectItem
            key={project.id}
            id={project.id}
            q={project.q}
            label={project.label}
            project_name={project.project_name}
            statusname={project.statusname}
            color={project.color}
            modername={project.modername}
            id_moder={project.id_moder}
            id_status={project.id_status}
            setShowProjectInfo={setShowProjectInfo}
            setSelectIdProject={setSelectIdProject}
            setPage={props.setPage}
            setIdProject={props.setIdProject}
            setProjectName={setProjectName}

            id_user={props.UserId}

            setChatName={props.setChatName}
            setChatMode={props.setChatMode}
            setModerName={setModerName}
            setModerId={setModerId}
            setStatusId={setStatusId}
            isFranch={props.isFranch}
            isModer={props.isModer}
            isMobile={props.isMobile}

            handleDeleteProject={handleDeleteProject}
            handleProjectInfo={handleProjectInfo}
            setTabUserModer={setTabUserModer}

            setProjectLabel={setProjectLabel}
            handleArchiveProject={handleArchiveProject}

            newMsgProjectId={newMsgProjectId}
            setNewMsgProjectId={setNewMsgProjectId}
        />

    })

    const renderListUsersProject = UsersProject.map(user => {
        return <UserItem
            key={user.id}
            id={user.id}
            username={user.username}
            is_stop={user.is_stop}
            // handleStopUser={handleStopUser}
            handleTakeOffUser={handleTakeOffUser}
            isFranch={props.isFranch}
            location={'project'}
            isMobile={props.isMobile}
            setShowUserInfo={setShowUserInfo}
            ShowUserInfo={ShowUserInfo}
        />
    })

    const renderListUsers = ListUsers.map(user => {
        return <UserItem
            key={user.id}
            id={user.id}
            username={user.username}
            is_stop={user.is_stop}
            handleStopUser={handleStopUser}
            handleDeleteUser={handleDeleteUser}
            isFranch={props.isFranch}
            location={'all'}

            setShowUserInfo={setShowUserInfo}
            ShowUserInfo={ShowUserInfo}
            handleProjectsOfUser={handleProjectsOfUser}
        />
    })

    const renderListModers = ListModers.map(moder => {
        return <ModerItem
            key={moder.id}
            id={moder.id_moder}
            moder_name={moder.moder_name}
            is_stop={moder.is_stop}
            isAdm={false}
            isFranch={props.isFranch}
            ModerId={ModerId}
            handleAppointModer={handleAppointModer}
        />
    })

    const renderListStatuses = ListStatuses.map(status => {
        return <Status
            key={status.id}
            id={status.id}
            statusname={status.name}
            StatusId={StatusId}
            handleSetStatus={handleSetStatus}
        />
    })

    const renderedListProject = listProject.map(project => {
        return (
            <div className='project'>
                {project.project_name}
            </div>
        )
    })

    const renderListProjectsArchive = listProjectsArchive.map(project => {
        return (
            <div className='project row'>
                <div className='col-5 text-start' onClick={() => {
                    console.log(9)
                    setShowArchive(false)
                    handleProjectInfo(project.id)
                    setShowProjectInfo(true)
                    setSelectIdProject(project.id)
                    setModerName(project.modername)
                    setModerId(project.id_moder)
                    setProjectName(project.project_name)
                    setStatusId(project.id_status)
                    setTabUserModer('users')
                }}>
                    {project.project_name}
                </div>
                <div className='col-3'>
                    <button className='btn btn-secondary btn-sm' onClick={() => handleActivateProject(project.id)}>
                        {'Активировать'}
                    </button>
                </div>
                <div className='col-2'>
                    <button className='btn btn-secondary btn-sm' onClick={() => {
                        if (window.confirm('Вы уверенны?')) {
                            handleDeleteArchProject(project.id)
                        }
                    }}>
                        {'Удалить'}
                    </button>
                </div>
            </div>
        )
    })

    function handleProjectsOfUser(id_user) {
        sendProjectsOfUser(id_user, function (data) {
            setListProject(data['projectsuser'])
        })
    }

    function addToListProjectsNewProject(id_project, project_name) {
        let arr = ListProjects.slice()
        arr.push({ 'id': id_project, 'project_name': project_name })
        setListProjects(arr)
        list_projects = arr
    }

    function addToUsersProject(id_user, username, is_stop) {
        let arr = UsersProject.slice()
        arr.push({ 'id': id_user, 'username': username, 'is_stop': is_stop })
        setUsersProject(arr)
    }

    function addToListUsers(id_user, username, is_stop) {
        let arr = ListUsers.slice()
        arr.push({ 'id': id_user, 'username': username, 'is_stop': is_stop })
        setListUsers(arr)
    }

    function setDeleteProject(id_project) {
        let arr = []
        ListProjects.forEach((project) => {
            if (project.id != id_project) {
                arr.push(project)
            }
        })
        setListProjects(arr)
        list_projects = arr
    }

    function setStopUser(id_user) {
        console.log('stop')
        let arr = ListUsers.slice()
        arr.forEach((user) => {
            if (user.id == id_user) {
                user.is_stop = !user.is_stop
            }
        })
        setListUsers(arr)
    }

    function setDeleteUser(id_user) {
        let arr = []
        if (ShowListUsers) {
            ListUsers.forEach((user) => {
                if (user.id != id_user) {
                    arr.push(user)
                }
            })
            setListUsers(arr)
        }
        else if (ShowProjectInfo) {
            UsersProject.forEach((user) => {
                if (user.id != id_user) {
                    arr.push(user)
                }
            })
            setUsersProject(arr)
        }

    }

    function appointModer(id_moder) {
        setModerId(id_moder)
        let moder_name = ''
        ListModers.forEach((moder) => {
            if (moder.id_moder == id_moder) {
                moder_name = moder.moder_name
            }
        })
        setModerName(moder_name)
    }

    function handleProjectInfo(id_project) {
        sendProjectInfo(id_project, function (data) {
            setUsersProject(data['usersproject'])
        })
    }

    function handleUsers(...args) {
        sendUsers(props.UserId, function (data) {
            if (args.length > 0) {
                let arr = []
                data['users'].forEach((user) => {
                    let isf = false
                    UsersProject.forEach((userproject) => {
                        if (userproject.id == user.id) {
                            isf = true
                        }
                    })
                    if (!isf) {
                        arr.push(user)
                    }
                })
                setListUsers(arr)
            }
            else {
                setListUsers(data['users'])
            }
        })
    }


    function handleArchive() {
        sendProjectsArchive(props.UserId, function (data) {
            console.log('handleArchive', data)
            setListProjectsArchive(data['projects'])
        })
    }

    function handleNewUser(username, usermail, mailPassword) {
        sendNewUser(props.UserId, username, usermail, mailPassword, function (data) {
            console.log('callback from sendNewUser', data)
            addToListUsers(data.id_user, username, false)
        })
    }

    function handleAddUser(id_user, username) {
        sendAddUserToProject(id_user, selectIdProject, function (data) {
            console.log('callback from sendAddUserToProject', data)
            addToUsersProject(id_user, username, false)
        })
    }

    function handleTakeOffUser(id_user) {
        sendTakeOffUserToProject(id_user, selectIdProject, function (data) {
            console.log('callback from sendTakeOffUserToProject', data)
            setDeleteUser(id_user)
        })
    }

    function handleStopUser(id_user, is_stop) {
        sendStopUser(id_user, is_stop, 'u', function (data) {
            if (data.res) {
                setStopUser(id_user)
            }
        })
    }

    function handleDeleteUser(id_user) {
        sendDeleteUser(id_user, function (data) {
            if (data.res) {
                setDeleteUser(id_user)
            }
        })
    }

    function handleProjects() {
        getUserProjects(props.UserId, '', function (data) {
            console.log('projects res', data)
            setListProjects(data['projects'])
            list_projects = data['projects']
        })
    }

    function handleNewProject(nameProject) {
        sendNewProject(nameProject, props.UserId, function (data) {
            addToListProjectsNewProject(data.id, nameProject)
        })
    }

    function handleDeleteProject(id_project) {
        sendDeleteProject(id_project, function (data) {
            if (data.res) {
                setDeleteProject(id_project)
            }
        })
    }

    function handleDeleteArchProject(id_project) {
        sendDeleteProject(id_project, function (data) {
            if (data.res) {
                handleArchive()
            }
        })
    }

    function handleArchiveProject(id_project) {
        sendArchiveProject(id_project, function (data) {
            if (data.res) {
                setDeleteProject(id_project)
            }
        })
    }


    function handleActivateProject(id_project) {
        sendActivateProject(id_project, function (data) {
            if (data.res) {
                handleProjects()
                handleArchive()
            }
        })
    }

    function handleSetStatus(id_status) {
        sendSetStatus(selectIdProject, id_status, function (data) {
            if (data.res) {
                setStatusId(id_status)
            }
        })
    }

    function handleFranchModers(id_franch) {
        console.log('handleFranchModers', id_franch)
        getFranchModers(id_franch, function (data) {
            console.log('moders res', data)
            setListModers(data['moders'])
        })
    }

    function handleStatuses() {
        console.log('handleStatuses')
        getStatuses(function (data) {
            console.log('statuses res', data)
            setListStatuses(data['statuses'])
        })
    }

    function handleAppointModer(id_moder) {
        sendAppointModer(selectIdProject, id_moder, function (data) {
            if (data.res) {
                appointModer(id_moder)
            }
        })
    }

    const handleShowListUsers = () => {
        handleUsers()
        setShowListUsers(true)
    }


    const handleShowArchive = () => {
        handleArchive()
        setShowArchive(true)
    }

    const closeModal = () => {
        setShowFormNewUser(false)
    }

    const handleSocketOn = (data) => {
        console.log('message from server', data);
        if (data.type === 'newmsgmoder') {
            dispatch(setArrivedNewModerMessage(true))
            setTimeout(()=>dispatch(setArrivedNewModerMessage(false)), 2000)
            setNewMsgProjectId(data.project_id)
            const find_index = list_projects.findIndex(prg => prg.id === data.project_id)
            console.log('find ', find_index)
            if (find_index !== -1) {
                let new_arr = list_projects.slice()
                console.log('q ', new_arr[find_index].q)
                new_arr[find_index].q =  new_arr[find_index].q  + 1 
                console.log('q ', new_arr[find_index].q)
                setListProject(new_arr)
                list_projects = new_arr
            }
        }
        else if (data.type === 'newmsg') {
            dispatch(setArrivedNewMessage(true))
            setTimeout(()=>dispatch(setArrivedNewMessage(false)), 2000)
            handleProjects()
        }
        dispatch(setArrivedNewMsgChatName(data.chatname))
    }

    useEffect(() => {
        console.log('subscribe socket', props.UserId.toString())
        socket.on(props.UserId.toString(), handleSocketOn);
        return () => {
            socket.removeAllListeners(props.UserId.toString());
        }
    }, []);

    useEffect(() => {
        console.log('moder', props.isModer)
        handleProjects()
        if (props.isFranch) {
            handleFranchModers(props.UserId)
        }
        if (props.isModer) {
            handleStatuses()
        }
    }, [state])
    return (
        <>
            <Modal
                isOpen={ShowFormNewUser}
                // onAfterOpen={}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <NewUser
                    setShowFormNewUser={setShowFormNewUser}
                    handleNewUser={handleNewUser}
                    isMobile={props.isMobile}
                />
            </Modal>

            {ShowListUsers && !showArchive ? (
                <>
                    {ShowUserInfo ? (
                        <div className='position-absolute top-50 start-50 translate-middle modal-projects border-black'>
                            <button
                                className='btn btn-secondary btn-sm'
                                onClick={() => {
                                    setShowUserInfo(false)
                                }}
                            >
                                {'Закрыть'}
                            </button>
                            <div >
                                {renderedListProject}
                            </div>
                        </div>
                    ) : null}
                    <div className='row'>
                        <div className='col-4' onClick={() => {
                            setShowListUsers(false)
                            // handleProjects()
                        }}>
                            <button className=' link-item  btn btn-light'>{'Назад'}</button>
                        </div>
                        <div className='col-8'>
                            {!props.isFranch ? null : (
                                <button className='link-item btn btn-light' onClick={() => {
                                    setShowFormNewUser(true)
                                }}>
                                    {'Новый участник'}
                                </button>
                            )}
                        </div>
                    </div>

                    <div className={props.isMobile ? 'p-1' : "p-5"}>
                        <h3 className="">{'Участники'}</h3>
                        <div className="d-flex justify-content-start">
                            <div className={props.isMobile ? 'w-90' : 'w-75'}>
                                <div>
                                    <div className='row'>
                                        <div className='col-4 fw-bold'>
                                            {'Имя'}
                                        </div>
                                        {/* <div className='col-2 fw-bold'>
                                            {'Статус'}
                                        </div> */}
                                    </div>
                                </div>
                                <div>
                                    {renderListUsers}
                                </div>
                            </div>
                        </div>

                        {/* {ShowFormNewUser ? (
                                
                        ): null} */}
                    </div>
                </>
            ) : null}

            {!ShowProjectInfo && !ShowListUsers && !showArchive ? (
                <>
                    {props.isFranch ? (
                        <div className='row '>
                            <div className="col-3  ">
                                <button className='btn btn-secondary fs-12' onClick={handleShowListUsers}>
                                    {'Реестр участников'}
                                </button>
                            </div>
                            <div className="col-4  me-5 d-flex justify-content-start">
                                <button className='btn btn-secondary  fs-12' onClick={handleShowArchive}>
                                    {'Архив'}
                                </button>
                            </div>
                            <div className='col-3'>
                                <button className='btn btn-outline-secondary  fs-12' onClick={() => {
                                    setShowFormNewProject(true)
                                }}>
                                    {'Создать проект'}
                                </button>
                            </div>
                        </div>
                    ) : null}

                    <div className={props.isMobile ? 'p-1 m-0' : "p-5 p-0 m-0"}>
                        <h3 className="">{'Проекты'}</h3>
                        <div className="d-flex justify-content-start">
                            <div className='w-100'>
                                <div>
                                    <div className='row'>
                                        <div className='col-5 fw-bold'>
                                            {/* {'Название'} */}
                                        </div>
                                        {props.isFranch && !props.isMobile && !props.isModer ? (
                                            <div className='col-2 fw-bold'>
                                                {''}
                                            </div>) : null}
                                        {!props.isMobile ?
                                            (<div className='col-1 fw-bold'>
                                                {/* {'Статус'} */}
                                            </div>) : null
                                        }
                                    </div>
                                </div>
                                <div>
                                    {renderListProjects}
                                </div>
                            </div>
                        </div>
                    </div>


                    {ShowFormNewProject ? (
                        <NewProject
                            setShowFormNewProject={setShowFormNewProject}
                            handleNewProject={handleNewProject}
                            isMobile={props.isMobile}
                        />
                    ) : null}
                    {/* {ShowFormNewModer ? (
                            <NewModer 
                                setShowFormNewModer={setShowFormNewModer} 
                                id_project={idModerProject}
                                refreshListProjects={refreshListProjects}
                            />
                        ): null} */}
                </>

            ) : null}

            {ShowProjectInfo && !ShowListUsers && !showArchive ? (
                <>
                    <div className='' onClick={() => {
                        setShowProjectInfo(false)
                        handleProjects()
                    }}>
                        <p className='text-start m-1 link-item w-25'>{'Назад'}</p>
                    </div>

                    <div className={props.isMobile ? 'row w-100 mt-3' : 'row w-50 mt-3'}>

                        {props.isFranch && !props.isModer ? (
                            <>
                                <div className='col-3 fw-bold'>
                                    {'Модератор: '}
                                </div>
                                <div className='col-4'>
                                    {ModerName == null ? 'Не назначен' : ModerName}
                                </div>
                            </>
                        ) : null}
                        <div className='col-4 fw-bold'>
                            {ProjectName}
                        </div>
                    </div>

                    {props.isMobile ? (
                        <>
                            <div className=''>
                                <div className='row mt-3'>
                                    <div className={TabUserModer == 'users' ? 'col-6 fw-bold' : 'col-6 text-color-blackgrey'}
                                        onClick={() => {
                                            setTabUserModer('users')
                                        }}
                                    >
                                        {'Участники'}
                                    </div>

                                    {props.isFranch && !props.isModer ? (
                                        <div className={TabUserModer == 'moders' ? 'col-6 fw-bold' : 'col-6 text-color-blackgrey'}
                                            onClick={() => {
                                                setTabUserModer('moders')
                                            }}
                                        >
                                            {'Модераторы'}
                                        </div>) : null}
                                    {props.isModer ? (
                                        <div className='col-5'>
                                            {/* <h3 className="">{'Статусы'}</h3> */}
                                        </div>
                                    ) : null}
                                </div>
                                {TabUserModer == 'users' ? (
                                    <>
                                        <div className='row'>
                                            <div className={!props.isModer || props.isFranch ? 'col-12 ms-3' : 'col-6 ms-3'}>
                                                {renderListUsersProject}
                                            </div>
                                            {props.isModer ? (
                                                <div className='col-5'>
                                                    <div className="d-flex justify-content-start">
                                                        <div className='w-100'>
                                                            <div>
                                                                {renderListStatuses}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>) : null}
                                        </div>
                                        {props.isFranch && !(UsersProject.length > 0 && props.isModer) ? (
                                            <div className='mt-5'>
                                                <button className='btn btn-secondary' onClick={() => {
                                                    handleUsers('p')
                                                    setShowSelectUser(true)
                                                }}>
                                                    {'Добавить участника'}
                                                </button>
                                            </div>) : null}
                                        {ShowSelectUser ? (
                                            <SelectUser
                                                ListUsers={ListUsers}
                                                setShowSelectUser={setShowSelectUser}
                                                handleAddUser={handleAddUser}
                                                isMobile={props.isMobile}
                                            />
                                        ) : null}

                                    </>
                                ) : null}
                                {TabUserModer == 'moders' ? (
                                    <>
                                        <div>
                                            {renderListModers}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </>
                    ) : (
                        <div className='row'>
                            <div className="p-5 col-7">
                                <h3 className="">{'Участники проекта'}</h3>
                                <div className="d-flex justify-content-start">
                                    <div className='w-50'>
                                        <div>
                                            {renderListUsersProject}
                                        </div>
                                    </div>
                                </div>
                                {props.isFranch && !(UsersProject.length > 0 && props.isModer) ? (
                                    <div className='mt-5'>
                                        <button className='btn btn-secondary' onClick={() => {
                                            handleUsers('p')
                                            setShowSelectUser(true)
                                        }}>
                                            {'Добавить участника'}
                                        </button>
                                    </div>
                                ) : null}
                                {ShowSelectUser ? (
                                    <SelectUser
                                        ListUsers={ListUsers}
                                        setShowSelectUser={setShowSelectUser}
                                        handleAddUser={handleAddUser}
                                    />
                                ) : null}
                            </div>

                            {props.isFranch && !props.isModer ? (
                                <div className='p-5 col-5'>
                                    <h3 className="">{'Модераторы'}</h3>
                                    <div className="d-flex justify-content-start">
                                        <div className='w-100'>
                                            <div>
                                                {renderListModers}
                                            </div>
                                        </div>
                                    </div>
                                </div>) : null}

                            {props.isModer ? (
                                <div className='p-5 col-5'>
                                    <h3 className="">{'Статусы'}</h3>
                                    <div className="d-flex justify-content-start">
                                        <div className='w-100'>
                                            <div>
                                                {renderListStatuses}
                                            </div>
                                        </div>
                                    </div>
                                </div>) : null}
                        </div>
                    )}



                </>
            ) : null}

            {showArchive ? (
                <>
                    <div className='w-100'>
                        <div>
                            <button className='btn btn-outline-secondary' onClick={() => {
                                setShowArchive(false)
                            }}>
                                {'Назад'}
                            </button>
                        </div>
                        <div>
                            {renderListProjectsArchive}
                        </div>
                    </div>
                </>
            ) : null}

        </>
    )
}


