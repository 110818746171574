import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';


export default function FileItem(props) {
  const filename = props.fileObj.name
  const ind = props.ind
  const indSelectFile = props.indSelectFile
  const isMobile = props.isMobile
  
  return (
    <>
      <div className='col-1 border p-0 m-0'>
        <div className={ind != indSelectFile ? ' anim h-5 overflow-hidden': ' animselect h-5 overflow-hidden'} onClick={() => {
            props.setSelectFile(props.fileObj)
            props.setIndSelectFile(ind)
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
              <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
          </svg>
          {/* {filename.slice(-3)} */}
          {isMobile ? null: (<p className='fs-10 text-wrap text-break overflow-hidden'>
            {filename}
          </p> )}         
        </div>
        <br/>
        {isMobile ? (
          <div className='' onClick={() => {
            props.delFileMessage(ind)
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
        </div>
        ): (<button className='btn btn-outline-secondary btn-sm m-1 h-3' onClick={() => {
          props.delFileMessage(ind)
        }}>
          <p className='fs-10'>{'удалить'}</p>
        </button>)}
      </div>
      
    </>
  )
}