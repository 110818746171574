import React from "react";

import { ExportMessageItem } from './exportMessageItem'
import './style.css'

const IMGFILES = ['png', 'jpg', 'peg', 'jpeg']

export class ExportChat extends React.Component {
    

        
    
    componentDidMount() {
        
        setTimeout(() => {
            
                console.log(999)
                const page_h = 854
                let d0 = document.getElementById(0);
                let d0_h = d0.offsetHeight
                d0.style.height = `${page_h}px`
                const d = document.getElementById(1);
                console.log('top', d0.offsetTop, d0_h, d.offsetHeight)
            
                let q_h = d0_h
            
                let small_el = false
                for (let i=1; i<this.props.listRows.length; i++) {
                    
                    const d = document.getElementById(i);
                    if (i===13) console.log( 13, d.offsetHeight)
            
                    if (d.offsetHeight > 850) {
                        if (small_el) d0.style.height = '850px'
                        d.style.height = '1720px'
                        small_el = false
                    }
                    else if (small_el && d.offsetHeight < 300) {
                        d.innerHTML = d0.innerHTML + d.innerHTML
                        d0.innerHTML = ''
                        d.style.height = '850px'
                        small_el = false
                    }
                    else if (d.offsetHeight < 300) small_el = true
                    else if (small_el) {
                        d0.style.height = '850px'
                        d.style.height = '850px'
                        small_el = false
                    }
                    else d.style.height = '850px'
                    
                    
                    d0 = document.getElementById(i);
                    
                }
                alert('Готово')
            
        }, 5000)
      }
    render() {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>{'Чат'}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.listRows.map((row, index) => {
                        return (
                            <div id={index} style={{ borderBottomWidth: '5px', borderBottomColor: 'black', marginTop: '10px'}}>

                                <ExportMessageItem
                                    ind={index}
                                    key={row.id}
                                    id={row.id}
                                    text={row.text}
                                    username={row.username}
                                    isOwn={row.id_user == this.props.UserId}
                                    filepath={row.filepath}
                                    filetype={row.filetype}
                                    filename={row.filename}
                                    idanswermessage={row.idanswermessage}
                                    isfav={row.isfav}
                                    // url={props.url}
                                    urlh={this.props.urlh}
                                    infoanswer={row.infoanswer}
                                    textDate={row.date.textDate}
                                    textTime={row.date.textTime}
                                    isMobile={this.props.isMobile}
                                />
                            </div>
                        )
                    })}
                </tbody>
            </table>
        );
    }
}
