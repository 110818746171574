import { useSocket } from "../hooks/useSocket";


// eslint-disable-next-line react-hooks/rules-of-hooks
const {socket} = useSocket()


// socket.on("connect", () => {
//     console.log('socket.id', socket.id); // ojIckSD2jqNzOqIrAGzL
//   });

// socket.on('login', data => {
//     console.log('login res', data['res'])
// })




export function parseurl(text) {
    //console.log('text', text)
    // var urlRegex = /(https?:\/\/[^ ]*)/;
    // let m = text.toString().match(urlRegex)
    // if (m != null) {
    //     var url = m[1];
    //     let indStart = text.indexOf(url)
    //     let indEnd = indStart + url.length
    //     let textbefore = text.substring(0, indStart)
    //     let textafter = text.substring(indEnd, text.length)
    //     return {'url': url, 'textbefore': textbefore, 'textafter': textafter}
    // } else {
    //     var url = ''
    //     let textbefore = text
    //     let textafter = ''
    //     return {'url': url, 'textbefore': textbefore, 'textafter': textafter}
    // }
    
    // new code
    var urlRegex = /(https?:\/\/[^ ]*)/;
    let m = text.toString().match(urlRegex)
    let url = ''
    let mess = []
    while (m != null) {
      url = m[1]
      console.log(url)
      let indStart = text.indexOf(url)
      let indEnd = indStart + url.length
      let textbefore = text.substring(0, indStart)
      mess.push({'type': 'text', 'text': textbefore})
      mess.push({'type': 'link', 'text': url})
      text = text.substring(indEnd, text.length)
      m = text.toString().match(urlRegex)
    }
    mess.push({'type': 'text', 'text': text})
    return mess
  }

export function dateMessage() {
    const curDate = new Date()
    let dateMessage = ''
    dateMessage += curDate.getFullYear()

    let month = curDate.getMonth()
    console.log('month', month.toString().length)
    if (month.toString().length == 1) {
        dateMessage += '/0' + month
    }
    else {
        dateMessage += '/' + month
    }
    
    let day = curDate.getDate()
    if (day.toString().length == 1) {
        dateMessage += '/0' + day
    }
    else {
        dateMessage += '/' + day
    }

    let hours = curDate.getHours()
    if (hours.toString().length == 1) {
        dateMessage += '/0' + hours
    }
    else {
        dateMessage += '/' + hours
    }

    let minute = curDate.getMinutes()
    if (minute.toString().length == 1) {
        dateMessage += '/0' + minute
    }
    else {
        dateMessage += '/' + minute
    }
    
    let second = curDate.getSeconds()
    if (second.toString().length == 1) {
        dateMessage += '/0' + second
    }
    else {
        dateMessage += '/' + second
    }
    
    return dateMessage    
}

export function sendNewProject(nameProject, user_id, callback) {
    
    socket.emit('newproject', {'project_name': nameProject, 'id_franch': user_id}, (response) => {
        console.log('callback from newproject', response)
        return callback(response)
    })
}   

export function sendNewUser(id_franch, username, usermail, mailPassword, callback) {
    console.log('send new user', id_franch)
    socket.emit('newuser', {'id_franch': id_franch, 'username': username, 'usermail': usermail, 'mailPassword': mailPassword}, (response) => {
        console.log('callback from newuser', response)
        return callback(response)
    })
    
}

export function sendRePassword(id_user, password, newpassword, callback) {
    console.log('sendRePassword', id_user)
    socket.emit('repassword', {'id_user': id_user, 'password': password, 'newpassword': newpassword}, (response) => {
        console.log('callback from sendRePassword', response)
        return callback(response)
    })
    
}

export function sendSetLabel(id_user, id_project, textLabel, callback) {
    console.log('sendSetLabel', id_project, textLabel)
    socket.emit('setlabel', {'id_user': id_user, 'id_project': id_project, 'textLabel': textLabel}, (response) => {
        console.log('callback from sendSetLabel', response)
        return callback(response)
    })
    
}

export function sendNewModer(id_franch, username, usermail, mailPassword, callback) {
    console.log('send new moder', id_franch)
    socket.emit('newmoder', {'id_franch': id_franch, 'username': username, 'usermail': usermail, 'mailPassword': mailPassword}, (response) => {
        console.log('callback from newmoder', response)
        return callback(response)
    })
}

export function sendAppointModer(id_project, id_moder, callback) {
    console.log('send app moder', id_project, id_moder)
    socket.emit('appmoder', {'id_project': id_project, 'id_moder': id_moder}, (response) => {
        console.log('callback from app moder', response)
        return callback(response)
    })
}

export function sendNewFranch(username, usermail, mailPassword, isModer, callback) {
    console.log('send new Franch')
    socket.emit('newfranch', {'username': username, 'usermail': usermail, 'mailPassword': mailPassword, 'isModer': isModer}, (response) => {
        console.log('callback from newfranch', response)
        return callback(response)
    })
    
}

export function sendLogin(username, password, token, user_id, callback) {
    console.log('token', token, user_id)
    socket.emit('login', {'username': username, 'password': password, 'token': token, 'user_id': user_id}, (response) => {
        return callback(response)
    })
}

export function sendUnlogin(id_user) {
    let t = localStorage.getItem('token')
    socket.emit('unlogin', {'id_user': id_user, 'token': t}, (response) => {
        console.log(response)
        localStorage.removeItem('token')
    })
}

export function sendFranchs(id_user, callback) {
    socket.emit('franchs', {'id_user': id_user}, (response) => {
        return callback(response)
    })
}

export function sendProjectsOfUser(id_user, callback) {
    socket.emit('projectsuser', {'id_user': id_user}, (response) => {
        return callback(response)
    })
}


export function sendUsersIsRead(id_user, id_message, callback) {
    socket.emit('usersisread', {'id_user': id_user, 'id_message': id_message}, (response) => {
        return callback(response)
    })
}


export function getFranchModers(id_franch, callback) {
    socket.emit('moders', {'id_user': id_franch}, (response) => {
        return callback(response)
    })
}

export function getUserProjects(id_user, id_adm, callback) {
    socket.emit('projects', {'id_user': id_user, 'id_adm': id_adm, 'is_arch': false}, (response) => {
        return callback(response)
    })
}

export function getProjectName(project_id, callback) {
    socket.emit('projectname', {'id_project': project_id}, (response) => {
        return callback(response)
    })
}

export function sendChat(id_user, id_project, callback) {
    socket.emit('chat', {'id_user': id_user, 'id_project': id_project}, (response) => {
        return callback(response)
    })
}

export function sendMessage(id_user, text, idanswer, date, id_project, username, chatname, callback) {
    socket.emit('newmmoder', 
        {
            'id_user': id_user, 
            'text': text, 
            'idanswer': idanswer, 
            'date': date,
            'id_project': id_project,
            'username': username,
            'chatname': chatname
        }, 
        (response) => {
            return callback(response)
        }
    )
}

export function sendStopUser(id_user, is_stop, type, callback) {
    console.log('stopuser', id_user, is_stop)
    socket.emit('stopuser', 
        {
            'id_user': id_user,
            'is_stop': is_stop,
            'type': type,
        }, 
        (response) => {
            return callback(response)
        }
    )
}

export function sendDeleteUser(id_user, callback) {
    console.log('deluser', id_user)
    socket.emit('deluser', 
        {
            'id_user': id_user,
        }, 
        (response) => {
            return callback(response)
        }
    )
}

export function sendDeleteProject(id_project, callback) {
    console.log('delproject', id_project)
    socket.emit('delproject', 
        {
            'id_project': id_project,
        }, 
        (response) => {
            return callback(response)
        }
    )
}

export function sendArchiveProject(id_project, callback) {
    console.log('archproject', id_project)
    socket.emit('archproject', 
        {
            'id_project': id_project,
        }, 
        (response) => {
            return callback(response)
        }
    )
}


export function sendActivateProject(id_project, callback) {
    console.log('activateproject', id_project)
    socket.emit('activateproject', 
        {
            'id_project': id_project,
        }, 
        (response) => {
            return callback(response)
        }
    )
}

export function sendProjectInfo(id_project, callback) {
    socket.emit('projectinfo', {'id': id_project}, 
        (response) => {
            return callback(response)
        }
    )
}


export function sendProjectsArchive(id_user, callback) {
    socket.emit('projects', {'id_user': id_user, 'id_adm': false, 'is_arch': true}, (response) => {
        return callback(response)
    })
}

export function getStatuses(callback) {
    socket.emit('statuses',  
        (response) => {
            return callback(response)
        }
    )
}

export function sendSetStatus(id_project, id_status, callback) {
    socket.emit('setstatus', {'id_project': id_project, 'id_status': id_status}, 
        (response) => {
            return callback(response)
        }
    )
}


export function sendModerator(id_user, id_project, callback) {
    console.log('id_user', id_user, 'id_project', id_project)
    socket.emit('moderator', {'id_user': id_user, 'id_project': id_project}, (response) => {
        return callback(response)
    })
}

export function sendAcceptMessage(id_message, username, chatname, callback) {
    socket.emit('acceptMessage', {'id': id_message, 'username': username, 'chatname': chatname}, (response) => {
        return callback(response)
    })
}

export function sendDeleteMessageModer(id_message, callback) {
    socket.emit('delMessageModer', {'id': id_message}, (response) => {
        return callback(response)
    })
}

export function sendUpdateMessageModer(id_message, text, DelFile, callback) {
    socket.emit('updateMessage', {'id': id_message, 'text': text, 'delfile': DelFile}, (response) => {
        return callback(response)
    })
}

export function sendSetFavorite(id_user, id_message, callback) {
    socket.emit('setfavorite', {'id_user': id_user, 'id_message': id_message}, (response) => {
        return callback(response)
    })
}

export function sendFavoriteMessages(id_user, id_project, callback) {
    socket.emit('messagefav', {'id_user': id_user, 'id_project': id_project}, (response) => {
        return callback(response)
    })
}

export function sendDelMessageFav(id_message, id_user, callback) {
    socket.emit('delmessagefav', {'id_message': id_message, 'id_user': id_user}, (response) => {
        return callback(response)
    })
}

export function sendMessageNotIsRead(id_user, id_project, callback) {
    socket.emit('messagenotisread', {'id_user': id_user, 'id_project': id_project}, (response) => {
        return callback(response)
    })
}

export function sendUsers(id_franch, callback) {
    socket.emit('users', {'id_franch': id_franch}, (response) => {
        return callback(response)
    })    
}

export function sendAddUserToProject(id_user, id_project, callback) {
    socket.emit('adduser', {'id_user': id_user, 'id_project': id_project}, (response) => {
        return callback(response)
    })
}

export function sendTakeOffUserToProject(id_user, id_project, callback) {
    socket.emit('takeoffuser', {'id_user': id_user, 'id_project': id_project}, (response) => {
        return callback(response)
    })
}

export function getOrder(id_user, callback) {
    console.log('getOrder', id_user)
    socket.emit('getorder', {'id_user': id_user}, (response) => {
        return callback(response)
    })
}  

//testmail
export function sendMail() {
    console.log('mail')
    socket.emit('sendmail',  (response) => {
        console.log('response', response)
    })
}










