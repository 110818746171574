import React, { useState, useEffect } from 'react'

export default function ModerItem(props) {
    const moder_name = props.moder_name
    const id = props.id
    const is_stop = props.is_stop
    const isFranch = props.isFranch
    const ModerId = props.ModerId
    const isMobile = props.isMobile

    return (
        <>
            <div className='row m-2'>
                <div className={isMobile ? 'text-start col-7 ms-2': 'text-start link-item col-3 m-2'} 
                    onClick={() => {
                        console.log(moder_name)
                }}>
                    {moder_name}
                </div>

                {isMobile ? (
                    <>
                        {props.isAdm ? (
                            <>
                                <div className='col-3' onClick={() => {
                                        props.handleStopModer(id, is_stop)
                                    }}
                                >
                                    {is_stop ? (
                                        <svg color="red" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-toggle2-off t-sc2" viewBox="0 0 32 32">
                                            <path d="M9 11c.628-.836 1-1.874 1-3a4.978 4.978 0 0 0-1-3h4a3 3 0 1 1 0 6H9z"/>
                                            <path d="M5 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10z"/>
                                        </svg>
                                    ): (
                                        <svg color='green' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-toggle-on t-sc2" viewBox="0 0 16 16">
                                            <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/>
                                        </svg>
                                    )}
                                </div>
                                <div className='col-1' onClick={() => {
                                    if (window.confirm('Вы уверенны?')) {
                                        props.handleDeleteModer(id)
                                    }
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3 t-sc2" viewBox="0 0 16 16">
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                                    </svg>
                                </div>
                            </>
                        ): (
                            <div className='col-2 font-s m-2 '>
                                {is_stop ? 'остановлен': 'действует'}
                            </div>
                        )}
                    </>
                    
                ): null}

                
                
                {!isMobile ? (
                    <>
                        <div className='col-2 font-s m-2 '>
                            {is_stop ? 'остановлен': 'действует'}
                        </div>
                        {props.isAdm ? (
                            <>
                                <div className='col-3 p-1'>
                                    <button className='btn btn-secondary font-s' onClick={() => {
                                        props.handleStopModer(id, is_stop)
                                    }}>
                                        {is_stop ? 'Возобновить': 'Остановить'}
                                    </button>
                                </div>
                                <div className='col-3 p-1'>
                                    <button className='btn btn-secondary font-s' onClick={() => {
                                        if (window.confirm('Вы уверенны?')) {
                                            props.handleDeleteModer(id)
                                        }
                                    }}>
                                        {'Удалить'}
                                    </button>
                                </div>
                            </>
                        ): null}
                    </>
                ): null}

                {isFranch ? (<div className='col-3 p-1 font-s ms-2'>
                    {ModerId != id ? 
                        <button className='btn btn-secondary' onClick={() => {
                            props.handleAppointModer(id)
                        }}>
                            {'Назначить'}
                        </button>: null
                    }
                </div>): null}

                {isFranch ? (
                    <>
                    </>
                ): null}

            </div>
            
        </>
    )
}