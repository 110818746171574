import React, { useEffect, useState } from 'react'

export default function NewModer(props) {
    const setShowFormNewModer = props.setShowFormNewModer
    const id_franch = props.id_franch
    const [username, setUsername] = useState('')
    const [usermail, setUsermail] = useState('')
    const [mailPassword, setMailPassword] = useState('')
    return (
        <>
            <div className={props.isMobile ? 
                "position-absolute top-50 start-50 translate-middle w-75 h-35 form-border bg-grey":
                "position-absolute top-50 start-50 translate-middle w-25 h-35 form-border bg-grey"}
            >
                <div className='d-flex justify-content-center flex-column p-2'>
                    <p>{'Новый модератор'}</p>
                    <input className='bg-white m-2' placeholder="Имя" value={username} 
                        onChange={(e) => {setUsername(e.target.value)}}>
                    </input>
                    {/* <input className='bg-white m-2' placeholder="Email" value={usermail} 
                        onChange={(e) => {setUsermail(e.target.value)}}>
                    </input> */}
                    <input className='bg-white m-2' placeholder="Email для пароля" value={mailPassword} 
                        onChange={(e) => {setMailPassword(e.target.value)}}>
                    </input>

                    <div className='w-100 row d-flex justify-content-center m-2'>
                        <button className='col-3 m-1 btn btn-light fs-12 p-0 py-2' onClick={() => {
                                console.log('id_franch', id_franch)
                                props.handleNewModer(username, usermail, mailPassword)
                                setShowFormNewModer(false)                                
                            }}>
                            {'Создать'}
                        </button>
                        <button className='col-3 m-1 btn btn-light fs-12 p-0 py-2' onClick={() => {
                            setShowFormNewModer(false)
                        }}>
                            {'Отмена'}
                        </button>
                    </div>
                </div>
                
                    
            </div>
        </>
    )
}