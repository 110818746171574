import { configureStore } from '@reduxjs/toolkit';

import messagemoderReducer from '../features/messagemoder/messagemoderSlice'
import mainReducer from '../features/mainSlice'

export const store = configureStore({
  reducer: {
    messagemoder: messagemoderReducer,
    main: mainReducer
  },
});
