import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch} from 'react-redux'


// import client from "socket.io-client"
import MessageItem from "./MessageItem"
// import MyCamera from './camera'

import { useRecordWebcam } from 'react-record-webcam'
import { sendMessageNotIsRead, sendDeleteMessageModer} from '../lib/funcs'



export function MessageNotIsRead(props) {
    const [ListMessages, setListMessage] = useState([])
    const [Socket, setSocket] = useState(null)
    const [Message, setMessage] = useState('')
    
    // const dispatch = useDispatch();

   
    function setMessageList(messages) {
        setListMessage(messages)
    }

    function delMessage(id_message) {
        let arr = []
        for (let i=0; i<ListMessages.length; i++) {
            if (ListMessages[i].id != id_message) {
                arr.push(ListMessages[i])
            }
        }
        setListMessage(arr)
    }

    function handleDelMessageModer(id_message) {
        sendDeleteMessageModer(id_message, function(data) {
            if (data.res) {
                delMessage(id_message)
            }
        })
    }

    
    const renderedListMessages = ListMessages.map(message => {
        return <MessageItem 
            key={message.id} 
            id={message.id}
            username={message.username}
            is_read={message.is_read}
            text={message.text}
            filepath={message.filepath}
            filetype={message.filetype}
            filename={message.filename}
            infoanswer={message.infoanswer}
            url={props.url}
            urlh={props.urlh}
            id_user={props.UserId}
            textDate={message.date.textDate}
            textTime={message.date.textTime}
            isMobile={props.isMobile}
            setSrcPreShowMessage={props.setSrcPreShowMessage}
            setTypePreShowMessage={props.setTypePreShowMessage}
            setPreShowMessage={props.setPreShowMessage}
            setNameDownloadImg={props.setNameDownloadImg}
            setidMessageShow={props.setidMessageShow}
            setPage={props.setPage}
            handleDelMessageModer={handleDelMessageModer}
          />
          
      })
    
    function handleMessageNotIsRead() {
        sendMessageNotIsRead(props.UserId, props.idProject, function(data) {
            setMessageList(data['messages'])
        })
    }

    useEffect(() => {
            handleMessageNotIsRead()
        // }
      }, [Socket])
    return (
        <>
            <div id='messages_not_isread' data-bs-spy="scroll" data-bs-offset="0" className='h-80 overflow-auto'>
                <ul className=''>
                    {renderedListMessages}
                </ul>
            </div>   
        </>
    )
}