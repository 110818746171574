import React, { useState } from "react";
// import "./styles.css";
import { Camera, FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

export default function MyCamera(props) {
  const [onCamera, setOnCamera] = useState(true)

  const setFiles_input = props.setFiles_input
  const setSelectFile = props.setSelectFile
  
  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}
  return (
    <div className="">
      <Camera
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        isImageMirror={false}
        isFullScreen={true}
        isMaxResolution={true}
        // idealResolution={{
        //   width: 3024,
        //   height: 4032
        // }}
        sizeFactor={1}
        onTakePhoto={(dataURI) => {
          let imgPhotoCamera = dataURLtoFile(dataURI, 'img' + props.UserId.toString() + randomIntFromInterval(1, 10000).toString() + '.png')
          let files_arr = []
          files_arr.push({'ind': 0, 'file': imgPhotoCamera});
          setFiles_input(files_arr)
          setSelectFile(files_arr[0]['file'])
          props.setContentMain('files')
          console.log(dataURI);
        }}
      />
    </div>
  );
}

