
// import * as React from "react";
// import { render } from "react-dom";
// import useRecorder from "./useRecorder";

// // import "./styles.css";

// export default function AudioRec(props) {
//   let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();
//   return (
//     <div className="position-absolute top-5">
//       <audio src={audioURL} controls />
//       <button onClick={startRecording} disabled={isRecording}>
//         start recording
//       </button>
//       <button onClick={stopRecording} disabled={!isRecording}>
//         stop recording
//       </button>
//       <button onClick={() => {
//           props.setdataRec(audioURL)
//       }}>
//           {'Прикрепить'}
//       </button>
//     </div>
//   );
// }
import React, { useEffect, useState } from 'react'

export default function AudioRec(props) {
    
  const uploadURL = props.urlh + "rec";
  
  const startButton = document.getElementById("toggle-rec-btn");
  let Recorder
  let blob
  const filetype = 'audio'
  const UserId = props.UserId
  const Message = props.Message
  const IdAnswer = props.IdAnswer
  const setFiles_input = props.setFiles_input
  const files_input = props.files_input
  const setContentMain = props.setContentMain
  const setSelectFile = props.setSelectFile
  const setIsAudio = props.setIsAudio

  let isRec = 'wait'

  function RecorderInit() {
    console.log('init')
    if (!navigator.mediaDevices) {
      console.error("getUserMedia not supported.")
      return;
    }

    const constraints = { audio: true };
    navigator.mediaDevices.getUserMedia(constraints)
    .then(function(stream) {
        let chunks = []
        Recorder = new MediaRecorder(stream);
        Recorder.ondataavailable = event => {
            // Collect all the chunks of the recording in an array.
            chunks.push(event.data);
        };
        Recorder.onstop = event => {
          console.log("Recording stopped.")
          // Create a blob with all the chunks of the recording.
          blob = new Blob(chunks, { 'type': 'audio/ogg' }); 
          chunks = [];
          
          sendRec()
          
        };
        Recorder.onstart = event => {
          console.log("Recording started.");
          
          // startButton.disabled = true;
          // Stop recording when the time is up.
          // setTimeout(function() { recorder.stop(); }, 3000);
        };
        Recorder.start()
        
    })
    .catch(function(err) {
        console.error(err);
    });
  }

  function sendRec() {
    let file = new File([blob], "name.ogg");
    console.log(file.type)
    let files_arr = files_input.slice() 
    let ind = files_arr.length
    files_arr.push({'ind': ind, 'file': file, 'blob': blob});
    console.log(files_arr)
    setFiles_input(files_arr)
    setSelectFile(files_arr[0]['file'])
    setContentMain('files')
    setIsAudio(false)
  }

  useEffect(() => {
    // if (isRec == 'wait') {
      RecorderInit()
    // }
  }, [])

  
  return (
    <>
      <div className='w-100'>
        {/* <button id='toggle-rec-btn' onClick={() => {
          console.log('rec')
          Recorder.start();
        }}> 
          {'start'}
        </button> */}
          
          <div onClick={() => {
            // if (isRec == 'wait') {
            //   RecorderInit()
            //   document.getElementById('record').style.display = 'none'
            //   document.getElementById('stoprec').style.display = 'inline'
            //   document.getElementById('stoprec').style.color = 'red'
              
            //   isRec = 'recing'
            // }
            // else if (isRec == 'recing') {
              // document.getElementById('stoprec').style.display = 'none'
              // document.getElementById('record').style.display = 'inline'
              Recorder.stop()
              
            // }
            
          }}>
            {/* <svg id='record' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mic t-sc link" viewBox="0 0 16 16">
                <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
                <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
            </svg> */}
            <svg 
              id='stoprec'  
              style={{color: 'red'}} 
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stop-circle link" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"/>
            </svg>
            {/* <svg id='send' style={{display: 'none'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
            </svg> */}
          </div>
          {/*
        {isRec == 'recing' ? (
          <div onClick={() => {
            Recorder.stop()
            setIsRec('ready')
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stop-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"/>
            </svg>
          </div>
        ): null}

        {isRec == 'ready' ? (
        <div onClick={() => {
          sendRec()
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
          </svg>
        </div>): null} */}

        {/* <button onClick={() => {
          Recorder.stop()
        }}>
          {'stop'}
        </button>  */}
        
        {/* <button onClick={() => {
          sendRec()
        }}>
          {'send'}
        </button> */}
      </div>
    </>
  )
}


