import React, { useEffect, useState } from 'react'


import ProjectItem from '../projects/ProjectItem'
import ModerItem from '../moders/ModerItem'
import NewModer from '../moders/newModer'
import UserItem from '../projects/UserItem'
import NewFranch from './newFranch'
import FranchItem from './FranchItem'
import { sendFranchs, getUserProjects, getFranchModers, sendNewModer, sendStopUser, sendDeleteUser, sendNewFranch} from '../lib/funcs'

export function Franchs(props) {
    const [selectIdProject, setSelectIdProject] = useState(0)
    const [idModerProject, setIdModerProject] = useState(0)
    const [selectIdModer, setSelectIdModer] = useState(0)
    const [ListProjects, setListProjects] = useState([])
    const [ListFranchs, setListFranchs] = useState([])
    const [ListModers, setListModers] = useState([])
    
    
    const [state, setState] = useState(0)
    const [ShowFormNewModer, setShowFormNewModer] = useState(false)
    const [ShowFormNewUser, setShowFormNewUser] = useState(false)
    const [ShowProjectInfo, setShowProjectInfo] = useState(false)
    const [ShowFormNewFranch, setShowFormNewFranch] = useState(false)

    const [FranchName, setFranchName] = useState('')
    
    const [ShowModerInfo, setShowModerInfo] = useState(false)
    const [ShowFranchInfo, setShowFranchInfo] = useState(false)
    const [ProjectsModer, setProjectsModer] = useState([])
    const [isModer, setIsModer] = useState(false)

    const [UsersProject, setUsersProject] = useState([])

    const [idFranch, setIdFranch] = useState(0)

    const [TabProjectModer, setTabProjectModer] = useState('projects')
    

    const renderListFranchs = ListFranchs.map(franch => {
        return <FranchItem 
            key={franch.id}
            id={franch.id} 
            setIdFranch={setIdFranch}
            franchname={franch.franchname}
            is_stop={franch.is_stop}
            setShowFranchInfo={setShowFranchInfo}
            handleFranchInfo={handleFranchInfo}
            handleFranchModers={handleFranchModers}
            handleStopFranch={handleStopFranch}
            handleDeleteFranch={handleDeleteFranch}
            setFranchName={setFranchName}
            isMobile={props.isMobile}
            is_moder={franch.is_moder}
            setIsModer={setIsModer}
            // setSelectIdModer={setSelectIdModer}
            // setPage={props.setPage}
            // setIdModer={props.setIdModer}
            // isModer={isModer}
            // setChatName={props.setChatName}
            // setChatMode={props.setChatMode}
          />
          
      })
    function setProjectLabel(id, textLabel) {
        console.log('setProjectLabel', id, textLabel)
        let arr = ListProjects.slice()
        for (let i=0; i<arr.length; i++) {
            if (arr[i].id == id) {
                arr[i]['label'] = textLabel
                break
            }
        }
        setListProjects(arr)
    }

    const renderListProjects = ListProjects.map(project => {
        return <ProjectItem 
            key={project.id}
            id={project.id} 
            label={project.label}
            project_name={project.project_name}
            role={'adm'}
            q={project.q}
            modername={project.modername}
            setPage={props.setPage}
            setIdProject={props.setIdProject}
            setChatName={props.setChatName}
            setChatMode={props.setChatMode}
            isMobile={props.isMobile}
            id_user={props.UserId}
            setProjectLabel={setProjectLabel}
            // setShowProjectInfo={setShowProjectInfo}
            // getProjectInfo={getProjectInfo}
            // setSelectIdProject={setSelectIdProject}
            // setPage={props.setPage}
            // setIdProject={props.setIdProject}
            // setChatName={props.setChatName}
            // setChatMode={props.setChatMode}
            // setIdModerProject={setIdModerProject}
            // setShowFormNewModer={setShowFormNewModer}
          />
          
      })
    
    const renderListModers = ListModers.map(moder => {
          return <ModerItem
            key={moder.id}
            id={moder.id_moder} 
            moder_name={moder.moder_name}
            is_stop={moder.is_stop}
            handleStopModer={handleStopModer}
            handleDeleteModer={handleDeleteModer}
            isAdm={true}
            isMobile = {props.isMobile}
          />
      })

    function addToListModersNewModer(id, id_moder, moder_name) {
        let arr = ListModers.slice()
        arr.push({'id': id, 'id_moder': id_moder, 'moder_name': moder_name})
        setListModers(arr)
    }

    function addToListFranchsNewFranch(id_franch, franchname) {
        let arr = ListFranchs.slice()
        arr.push({'id': id_franch, 'franchname': franchname})
        setListFranchs(arr)
    }

    function setStopModer(id_moder) {
        let arr = ListModers.slice()
        arr.forEach((moder) => {
            if (moder.id_moder == id_moder) {
                moder.is_stop = !moder.is_stop
            }
        })
        setListModers(arr)
    }

    function setStopFranch(id_franch) {
        let arr = ListFranchs.slice()
        arr.forEach((franch) => {
            if (franch.id == id_franch) {
                franch.is_stop = !franch.is_stop
            }
        })
        setListFranchs(arr)
    }

    function setDeleteModer(id_moder) {
        let arr = []
        ListModers.forEach((moder) => {
            if (moder.id_moder != id_moder) {
                arr.push(moder)
            }
        })
        setListModers(arr)
    }

    function setDeleteFranch(id_franch) {
        let arr = []
        ListFranchs.forEach((franch) => {
            if (franch.id != id_franch) {
                arr.push(franch)
            }
        })
        setListFranchs(arr)
    }
   
    function handleFranchInfo(id_franch) {
        console.log('handleFranchInfo', props.UserId, id_franch)
        getUserProjects(id_franch, props.UserId, function(data) {
            console.log('projects res', data)
            setListProjects(data['projects'])
        })
        // Socket.emit('projects', {'id_user': id_franch})
    }

    function handleFranchModers(id_franch) {
        console.log('handleFranchModers', id_franch)
        getFranchModers(id_franch, function(data) {
            console.log('moders res', data)
            setListModers(data['moders'])
        })
    }

    function handleFranchs() {
        console.log('send franchs')
        sendFranchs(props.UserId, function(data) {
            console.log('franchs res', data)
            setListFranchs(data['franchs'])
        })
    }

    function handleNewFranch(username, usermail, mailPassword, isModer) {
        sendNewFranch(username, usermail, mailPassword, isModer, function(data) {
            console.log('callback from sendNewFranch', data)
            addToListFranchsNewFranch(data.id, username)
        })
    }

    function handleNewModer(username, usermail, mailPassword) {
        sendNewModer(idFranch, username, usermail, mailPassword, function(data) {
            console.log('callback from sendNewModer', data)
            addToListModersNewModer(data.id, data.id_moder, username)
        })
    }

    function handleStopModer(id_moder, is_stop) {
        sendStopUser(id_moder, is_stop, 'm', function(data) {
            if (data.res) {
                setStopModer(id_moder)
            }
        })
    }

    function handleStopFranch(id_franch, is_stop) {
        sendStopUser(id_franch, is_stop, 'f', function(data) {
            if (data.res) {
                setStopFranch(id_franch)
            }
        })
    }

    function handleDeleteModer(id_moder) {
        sendDeleteUser(id_moder, function(data) {
            if (data.res) {
                setDeleteModer(id_moder)
            }
        })
    }

    function handleDeleteFranch(id_franch) {
        sendDeleteUser(id_franch, function(data) {
            if (data.res) {
                setDeleteFranch(id_franch)
            }
        })
    }

    useEffect(() => {
        handleFranchs()
    }, [state])
    return (
        <>

            {!ShowFranchInfo ? (
                <>
                
                <div className={props.isMobile ? 'p-1': "p-5"}>
                                <h3 className="">{'Франчайзи'}</h3>
                                <div className="d-flex justify-content-start">
                                    <div className={props.isMobile ? 'w-100': 'w-50'}>
                                        <div>
                                            {renderListFranchs}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button className='btn btn-secondary' onClick={() => {
                                    setShowFormNewFranch(true)
                                }}>
                                    {'Создать франчайзи'}
                                </button>
                            </div>
                            {ShowFormNewFranch ? (
                                    <NewFranch 
                                        setShowFormNewFranch={setShowFormNewFranch} 
                                        user_id={props.UserId}
                                        handleNewFranch={handleNewFranch}
                                        isMobile={props.isMobile}
                                    />
                                ): null}
                </>
            ): null}

            {ShowFranchInfo ? (
                <>
                    <div className='' onClick={() => {
                        setShowFranchInfo(false)
                    }}>
                        <p className='text-start m-1 link-item w-25'>{'Назад'}</p>
                    </div>

                    {!props.isMobile ? (
                        <div className='row'>
                            <div className="p-5 col-6">
                                <h3 className="mb-5">{'франчайзи: ' + FranchName}</h3>
                                <div className="d-flex justify-content-start">
                                    <div className='w-100'>
                                        <div>
                                            <div className='row'>
                                                <div className='col-4 fw-bold'>
                                                    {'Проекты'}
                                                </div>
                                                {/* <div className='col-4 fw-bold'>
                                                    {'Модератор'}
                                                </div> */}
                                                <div className='col-4'>
                                                    {''}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {renderListProjects}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-5 col-6">

                                {!isModer ? (
                                    <>
                                    
                                        <div className="mb-5 link-item btn btn-secondary" onClick={() => {
                                            setShowFormNewModer(true)
                                        }}>
                                            {'Регистрация модератора'}
                                        </div>

                                        <div className="d-flex justify-content-start">
                                            <div className='w-100'>
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-3 fw-bold'>
                                                            {'Модераторы'}
                                                        </div>
                                                        <div className='col-3 fw-bold'>
                                                            {'Статус'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {renderListModers}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ): null}

                                
                            </div>
                        </div>
                    ): (
                        <div className=''>
                            <h5 className="mb-5">{'франчайзи: ' + FranchName}</h5>
                            <div className='row'>
                                <div className={TabProjectModer == 'projects' ? 'col-6 fw-bold': 'col-6 text-color-blackgrey'}
                                    onClick={() => {
                                        setTabProjectModer('projects')
                                    }}
                                >
                                    {'Проекты'}
                                </div>
                                {!isModer ? (
                                <div className={TabProjectModer == 'moders' ? 'col-6 fw-bold': 'col-6 text-color-blackgrey'}
                                    onClick={() => {
                                        setTabProjectModer('moders')
                                    }}
                                >
                                    {'Модераторы'}
                                </div>): null}
                            </div>
                            {TabProjectModer == 'projects' ? (
                                <>
                                    <div className='w-90 ms-3'>
                                        {renderListProjects}
                                    </div>
                                </>
                            ): null}
                            {TabProjectModer == 'moders' ? (
                                <>
                                    <div className="my-3 link-item btn btn-outline-secondary btn-sm" onClick={() => {
                                        setShowFormNewModer(true)
                                    }}>
                                        {'Регистрация модератора'}
                                    </div>
                                    <div>
                                        {renderListModers}
                                    </div>
                                </>
                            ): null}
                        </div>
                    )}

                    {ShowFormNewModer ? (
                            <NewModer 
                                setShowFormNewModer={setShowFormNewModer} 
                                id_franch={idFranch}
                                handleNewModer={handleNewModer}
                                isMobile={props.isMobile}
                            />
                        ): null}
                </>
            ): null}
            
        </>
    )
}


