import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Login } from './login/Login';
import { Register } from './register/Register';
import { Chat } from './chat/Chat';
// import { Projects } from './features/projects/Projects'; 
import { MessageModer } from './messagemoder/MessageModer';
import { MessageFav } from './messagefav/MessageFav';
import { MessageNotIsRead } from './messagenotisread/MessageNotIsRead'
import { ChatProjects } from './projects/ChatProjects'
import { Franchs } from './franchs/Franchs'
import { saveAs } from 'file-saver'
import { FormOrder } from './formorder/form';

import { sendUnlogin, sendRePassword, getProjectName } from './lib/funcs';


import logoimg from '../img/logo.png'


import '../App.css';
import { useParams } from 'react-router-dom';
import { useSocket } from './hooks/useSocket';
import { useDispatch, useSelector } from 'react-redux';
import { selectArrivedNewMessage, selectArrivedNewModerMessage, selectArrivedNewMsgChatName, setArrivedNewModerMessage } from './mainSlice';

function Main() {
  const SOCKET_URI = 'https://u102315.na4u.ru/'
  const SOCKET_URIH = 'https://u102315.na4u.ru/'
  // const SOCKET_URI = 'ws://localhost:5000/'
  // const SOCKET_URIH = 'http://localhost:5000/'

  const dispatch = useDispatch()
  const { page_id } = useParams();
  const { socket } = useSocket()

  const [Page, setPage] = useState('')
  const [IsLogin, setIsLogin] = useState(false)
  const [UserId, setUserId] = useState('')
  const [UserName, setUserName] = useState('')
  const [ChatName, setChatName] = useState('')

  const [isModer, setIsModer] = useState(false)
  const [isFranch, setIsFranch] = useState(false)
  const [isAdm, setIsAdm] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [idProject, setIdProject] = useState(0)
  const [chatMode, setChatMode] = useState(false)

  const [idMessageShow, setidMessageShow] = useState(-1)
  const [ChatPanelShow, setChatPanelShow] = useState(false)

  const [ShowMobileMenu, setShowMobileMenu] = useState(false)

  const [preShowMessage, setPreShowMessage] = useState(false)
  const [SrcPreShowMessage, setSrcPreShowMessage] = useState('')
  const [typePreShowMessage, setTypePreShowMessage] = useState('')
  const [nameDownloadImg, setNameDownloadImg] = useState('')

  const [Password, setPassword] = useState('')
  const [NewPassword, setNewPassword] = useState('')
  const [ShowRePassword, setShowRePassword] = useState(false)
  const [showModalOrder, setShowModalOrder] = useState(false)

  const arrivedNewModerMessage = useSelector(selectArrivedNewModerMessage)
  const arrivedNewMessage = useSelector(selectArrivedNewMessage)
  const arrivedNewMsgChatName = useSelector(selectArrivedNewMsgChatName)

  const closeModalOrder = () => {
    setShowModalOrder(false)
  }

  const handleClickSendOrder = () => {
    setShowModalOrder(true)
  }

  let isChat = false

  const downloadImage = () => {
    console.log('download')
    saveAs(SrcPreShowMessage, nameDownloadImg)
  }

  const handleMenuModer = () => {
    setPage('moder')
    dispatch(setArrivedNewModerMessage(false))
  }

  function Navbar() {


    return (
      <>
        <div className={isMobile ? ' bg-black fixed-top p-0 m-0  h-15' : " bg-black fixed-top h-5 d-flex align-items-center m-0 p-0"}>
          {arrivedNewModerMessage && <div className='new_mdr_msg'>модерация! {arrivedNewMsgChatName}</div>}
          {arrivedNewMessage && <div className='new_mdr_msg'>чат! {arrivedNewMsgChatName}</div>}
          <div className='w-100'>
            <div className='row w-100 d-flex align-items-center menu_main'>
              <a className={isMobile ? 'col-3' : "col-2"} href="#" onClick={() => {
                sendUnlogin(UserId)
                setChatMode(false)
                setPage('login')
                // setIsLogin(false)
              }}>
                <img className={isMobile ? 'logoM' : 'logo'} src={logoimg} />
              </a>

              {Page === 'projects' ? (
                <div className='col-2 btn btn-secondary font-s' onClick={handleClickSendOrder}>
                  {isMobile ? 'Заказ' : 'Отправить заказ'}
                </div>
              ) : null}

              {IsLogin && isAdm !== 1 ? (
                <div className={isMobile ? "col-3" : 'col-4'} onClick={() => {
                  setChatMode(false)
                  setChatName('')
                  setPage('projects')
                }}>
                  <span className={Page == 'projects' ? 'text-color-white mt-3 isselect' : 'text-color-white mt-3 navbutton'}>
                    {'Проекты'}
                  </span>
                </div>) : null}

              {IsLogin && isAdm == 1 ? (
                <div className="col-3" onClick={() => {
                  setChatMode(false)
                  setPage('franchs')
                }}>
                  <span className={Page == 'franchs' ? 'text-color-white mt-3 isselect' : 'text-color-white mt-3 navbutton'}>
                    {'Франчайзи'}
                  </span>
                </div>) : null}

              {IsLogin && !isMobile ? (
                <>
                  <div className='menu_user_name'>
                    <div className='text-color-grey t-al user_name'>
                      {UserName + (isAdm ? '  админ' : (isFranch ? '    франчайзи' : (isModer ? '    модератор' : '   участник')))}
                    </div>
                  </div>
                  {isAdm ?
                    <div className='col-1 text-color-grey' onClick={() => {
                      setShowRePassword(!ShowRePassword)
                    }}>
                      <p className='mt-3 link-color'>{'Смена пароля'}</p>
                    </div> : null}

                </>
              ) : null}
            </div>

            {IsLogin && isMobile ? (
              <>
                <div className='menu_user_name_m'>
                  <div className='text-color-grey user_name'>
                    {UserName + (isAdm ? '  админ' : (isFranch ? '    франчайзи' : (isModer ? '    модератор' : '   участник')))}
                  </div>
                </div>
                {isAdm ?
                  <div className='col-1 text-color-grey' onClick={() => {
                    setShowRePassword(!ShowRePassword)
                  }}>
                    <p className='mt-3 link-color'>{'Смена пароля'}</p>
                  </div> : null}

              </>
            ) : null}
          </div>


          { Page === 'chat' &&
            <div className='p-0 m-0 chat_name'>
              <div className={isMobile ? 'text-color-grey font-s t-ar' : 'text-color-grey t-ar'}>
                {ChatName.slice(0, 45)}
              </div>
            </div>
          }
        </div>

        <div className={isMobile ? 'h-15' : 'h-5'}>

        </div>
        {!preShowMessage ? (
          <div className={ChatPanelShow && !preShowMessage ? ('row_menu bg-lightblack chatpanel-show ') : ('row_menu bg-lightblack h-5h chatpanel')} id='pan'>
            {isFranch || isModer === 1 ? (
              <>
                <div className={isMobile ? 'col-3 mt-2 navbutton_m' : 'col-2 navbutton'} onClick={handleMenuModer}>
                  <span className={Page == 'moder' ? 'text-color-white mt-3 isselect' : 'text-color-white mt-3'}>
                    {'Модерация'}
                  </span>
                </div>
              </>
            ) : null}

            {true ? (<div className={isMobile ? 'col-3 mt-2 navbutton_m' : 'col-2 navbutton'} onClick={() => { setPage('chat') }}>
              <span className={Page == 'chat' ? 'text-color-white mt-3 isselect' : 'text-color-white mt-3 '}>
                {'Чат'}
              </span>
            </div>) : null}

            {true ? (
              <div className={isMobile ? 'col-3 mt-2 navbutton_m' : 'col-2 navbutton'} onClick={() => {
                setPage('favorite')
              }}>
                <span className={Page == 'favorite' ? 'text-color-white mt-3 isselect' : 'text-color-white mt-3'}>
                  {'Избранное'}
                </span>
              </div>) : null}

            {!isFranch && isModer != 1 ? (
              <div className={isMobile ? 'col-4 mt-2 navbutton_m' : 'col-2 navbutton'} onClick={() => { setPage('notisread') }}>
                <span className={Page == 'notisread' ? 'text-color-white mt-3 isselect' : 'text-color-white mt-3'}>
                  {'В обработке'}
                </span>
              </div>) : null}

            {isMobile ? (
              <div className='col-2 d-flex align-items-center  justify-content-center' onClick={() => {
                setShowMobileMenu(!ShowMobileMenu)
              }}>
                <svg color='white' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="t-sc bi bi-three-dots-vertical" viewBox="0 0 16 16">
                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              </div>) : null}

          </div>) : null}
      </>
    )
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-30%',
      transform: 'translate(-50%, -50%)',
    },
  };






  useEffect(() => {




    setIsMobile(window.innerWidth <= 500)
    // document.getElementById('chatpanel').classList.add('hide-chat-panel')
  })

  useEffect(() => {
    console.log('effect chatmode')
    if (chatMode) {

      const pan = document.getElementById('pan')
      pan.className = 'row bg-lightblack h-5h anim-chat-panel'
      setTimeout(() => {
        setChatPanelShow(true)
        isChat = true
      }, 1000)
    } else {
      isChat = false
      setChatPanelShow(false)
      setChatName('')
    }
  }, [chatMode])

  useEffect(() => {
    console.log('page: ', page_id)
    if (page_id) {
      setIsLogin(true)
      if (page_id.slice(0, 4) === 'chat') {
        let arr_params = page_id.slice(4).split('@')
        let user_id = arr_params[0]
        let project_id = arr_params[1]
        let username = arr_params[2]
        let chatname = ''
        getProjectName(project_id, (data) => {
          console.log('getProjectName ', getProjectName)
          chatname = data.project_name
          setUserId(user_id)
          setIdProject(project_id)
          setUserName(username)
          setChatName(chatname)
          setChatMode(true)
          setPage('chat')
        })        
      }
      else if (page_id.slice(0, 4) === 'modr') {
        let arr_params = page_id.slice(4).split('@')
        let user_id = arr_params[0]
        let project_id = arr_params[1]
        let username = arr_params[2]
        let chatname = ''
        getProjectName(project_id, (data) => {
          console.log('getProjectName ', getProjectName)
          chatname = data.project_name
          setUserId(user_id)
          setIdProject(project_id)
          setUserName(username)
          setChatName(chatname)
          setChatMode(true)
          setIsModer(1)
          setPage('moder')
        })
        // let chatname = arr_params[3]

      }
    }
    else setPage('login')
  }, []);

  return (
    <div className="App">


      <Modal
        isOpen={showModalOrder}
        // onAfterOpen={}
        onRequestClose={closeModalOrder}
        style={customStyles}
      >
        <div>
          <FormOrder urlh={SOCKET_URIH} id_user={UserId} isMobile={isMobile} setShowModalOrder={setShowModalOrder} />
        </div>
      </Modal>

      {preShowMessage ? (
        <>


          {typePreShowMessage == 'img' ? (
            <>
              <div className='d-flex justify-content-start zi-2  fixed-top bg-white h-9'>
                <div className=' m-4 link-cursor' onClick={() => {
                  setidMessageShow(-1)
                  setPreShowMessage(false)

                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class=" bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                  </svg>
                </div>
                <div className='m-4 link-cursor' onClick={() => {
                  downloadImage()
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class=" bi bi-download" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </div>
                <div className='m-4 link-cursor' onClick={() => {
                  let preIMG = document.getElementById('pre-img')
                  var zoomNow = +getComputedStyle(preIMG).zoom;
                  preIMG.style.zoom = zoomNow + 0.1;
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </div>
                <div className='m-4 link-cursor' onClick={() => {
                  let preIMG = document.getElementById('pre-img')
                  var zoomNow = +getComputedStyle(preIMG).zoom;
                  preIMG.style.zoom = zoomNow - 0.1;
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-square" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                  </svg>
                </div>

              </div>
              <div className='ctnr-pre-img zi-2' >
                <div className='cntnr zi-2'>
                  <div className=' overflow-auto mt-5 zi-2'>
                    <img
                      id='pre-img'
                      className='h-m90  zi-2'
                      src={SrcPreShowMessage}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null
          }

          {typePreShowMessage == 'pdf' ?
            (
              <div className='ctnr-pre-pdf pt'>
                <div className='d-flex justify-content-start zi-2  fixed-top bg-white h-5h'>
                  <div className=' m-1 me-2 link-cursor' onClick={() => {
                    setidMessageShow(-1)
                    setPreShowMessage(false)

                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class=" bi bi-arrow-left" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                  </div>
                  <div className='m-1 link-cursor' onClick={() => {
                    downloadImage()
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class=" bi bi-download" viewBox="0 0 16 16">
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </div>

                </div>
                <iframe
                  src={SrcPreShowMessage}
                  // height="100%"
                  // type="application/pdf" 
                  width="100%"
                  height="100%"
                  // width="100%" 
                  className='h-m90'
                // data={SrcPreShowMessage}
                />
              </div>

            ) : null}


        </>
      ) : null
      }
      {
        ShowRePassword ? (
          <>
            <div className='d-flex justify-content-center position-absolute top-50 start-50 translate-middle bg-grey rounded'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-x-circle m-2 t-sc2" viewBox="0 0 16 16"
                onClick={() => {
                  setShowRePassword(false)
                }}
              >

                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
              <div className='w-100 mt-5'>
                <div className="m-3">
                  <input id="password-field" type="password" className="form-control" value={Password} placeholder="Пароль" onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="m-3">
                  <input id="password-field" type="password" className="form-control" value={NewPassword} placeholder="Новый пароль" onChange={(e) => setNewPassword(e.target.value)} />
                </div>
                <div className="form-element">
                  <button className="btn btn-secondary m-3"
                    onClick={() => {
                      console.log('send re passw')
                      sendRePassword(UserId, Password, NewPassword, function (data) {
                        if (data.res) {
                          alert('Пароль успешно изменен')
                          setShowRePassword(false)
                        }
                        else {
                          alert('Неверные данные')
                          setShowRePassword(false)
                        }

                      })
                    }}
                  >
                    {'Изменить пароль'}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : null
      }
      {!preShowMessage ? (<Navbar />) : null}
      {Page == 'moder' ?
        <MessageModer
          UserId={UserId}
          url={SOCKET_URI}
          urlh={SOCKET_URIH}
          idProject={idProject}
          ChatName={ChatName}
          isFranch={isFranch}
          isModer={isModer}
          setidMessageShow={setidMessageShow}
          setPage={setPage}
          isMobile={isMobile}
          setPreShowMessage={setPreShowMessage}
          setSrcPreShowMessage={setSrcPreShowMessage}
          setTypePreShowMessage={setTypePreShowMessage}
          setNameDownloadImg={setNameDownloadImg}
        /> : null}
      {Page === 'chat' && UserId != '' ?
        <Chat
          UserId={UserId}
          UserName={UserName}
          ChatName={ChatName}
          url={SOCKET_URI}
          urlh={SOCKET_URIH}
          idProject={idProject}
          idMessageShow={idMessageShow}
          setidMessageShow={setidMessageShow}
          isFranch={isFranch}
          isModer={isModer}
          isMobile={isMobile}
          ShowMobileMenu={ShowMobileMenu}
          setShowMobileMenu={setShowMobileMenu}
          preShowMessage={preShowMessage}
          setPreShowMessage={setPreShowMessage}
          setSrcPreShowMessage={setSrcPreShowMessage}
          setTypePreShowMessage={setTypePreShowMessage}
          setNameDownloadImg={setNameDownloadImg}
        /> : null}
      {/* {Page == 'projects' && UserId != '' ? <Projects UserId={UserId} url={SOCKET_URI} urlh={SOCKET_URIH}/>: null}  */}
      {Page == 'franchs' ?
        <Franchs
          UserId={UserId}
          url={SOCKET_URI}
          urlh={SOCKET_URIH}
          setPage={setPage}
          setIdProject={setIdProject}
          setIsModer={setIsModer}
          setChatName={setChatName}
          setChatMode={setChatMode}
          isMobile={isMobile}
        /> : null
      }
      {Page == 'projects' ?
        <ChatProjects
          UserId={UserId}
          url={SOCKET_URI}
          urlh={SOCKET_URIH}
          setPage={setPage}
          setIdProject={setIdProject}
          setIsModer={setIsModer}
          setChatName={setChatName}
          setChatMode={setChatMode}
          isFranch={isFranch}
          isAdm={isAdm}
          isModer={isModer}
          isMobile={isMobile}
        /> : null}
      {Page == 'login' ?
        <Login
          setPage={setPage}
          setUserId={setUserId}
          setUserName={setUserName}
          setIsModer={setIsModer}
          setIsLogin={setIsLogin}
          isLogin={IsLogin}
          setIsFranch={setIsFranch}
          setIsAdm={setIsAdm}
          url={SOCKET_URI}
          isMobile={isMobile}
          setIdProject={setIdProject}
        />
        : null
      }
      {Page == 'registration' ? <Register setPage={setPage} url={SOCKET_URI} /> : null}
      {Page == 'favorite' ?
        <MessageFav
          setPage={setPage}
          url={SOCKET_URI}
          urlh={SOCKET_URIH}
          UserId={UserId}
          idProject={idProject}
          setidMessageShow={setidMessageShow}
          isMobile={isMobile}
          preShowMessage={preShowMessage}
          setPreShowMessage={setPreShowMessage}
          setSrcPreShowMessage={setSrcPreShowMessage}
          setTypePreShowMessage={setTypePreShowMessage}
          setNameDownloadImg={setNameDownloadImg}
        /> : null}
      {Page == 'notisread' ?
        <MessageNotIsRead
          setidMessageShow={setidMessageShow}
          setPreShowMessage={setPreShowMessage}
          setSrcPreShowMessage={setSrcPreShowMessage}
          setTypePreShowMessage={setTypePreShowMessage}
          setNameDownloadImg={setNameDownloadImg}
          setPage={setPage}
          url={SOCKET_URI}
          urlh={SOCKET_URIH}
          UserId={UserId}
          idProject={idProject}
          isMobile={isMobile}
        /> : null}
    </div>
  );
}

export default Main;
