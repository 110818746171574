import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'
import TextareaAutosize from 'react-textarea-autosize';

import {parseurl} from '../lib/funcs'
import FileItem from '../lib/components'

const IMGFILES = ['png', 'jpg', 'jpeg', 'JPG', 'PNG']

export default function MessageItem(props) {
  const text = props.text
  const url_text = props.url_text
  const id = props.id
  const username = props.username
  const filepath = props.filepath
  const filetype = props.filetype
  const filename = props.filename
  const infoanswer = props.infoanswer
  const textDate = props.textDate
  const textTime = props.textTime
  // const files = props.files
  const isMobile = props.isMobile

  const [changeMode, setChangeMode] = useState(false)
  const [Message, setMessage] = useState('')
  const [DelFile, setDelFile] = useState(false)
  const [beforText, setBeforText] = useState('')
  const [afterText, setAfterText] = useState('')
  const [urlText, setUrlText] = useState('')
  const [listPartsMessage, setListPartsMessage] = useState([])

  function removeEvListScroll() {
    
  }

  const downloadImage = () => {
    console.log('saave')
    saveAs(props.urlh + 'static/uploads/' + filepath) // Put your image url here.
    // fetch(props.urlh + 'static/img/' + filepath, {method: "GET", mode: 'no-cors',})
    // .then((data) => {
    //     console.log(data);
    //   });
  }

  const downloadFile = (filepath) => {
    console.log('saave')
    saveAs(props.urlh + 'static/img/' + filepath) // Put your image url here.
    // fetch(props.urlh + 'static/img/' + filepath, {method: "GET", mode: 'no-cors',})
    // .then((data) => {
    //     console.log(data);
    //   });
  }

  // const renderedListPartsMessage = listPartsMessage.map(part => {
    const renderedListPartsMessage = url_text.map(part => {
    return (
      <>
        {part.type == 'text' ? (
          <p className='wa p-0 m-0'>{' ' + part.text + ' '}</p>
        ): (
          <a href={part.text} target="_blank">{part.text}</a>
        )}
      </>
    )
  })

  // const handleScroll = e => {
  //   const list_el = document.getElementById('modermessages')
  //   const el = document.getElementById(id)
  //   if (el !== null) {
  //     console.log('s', el.offsetTop, list_el.scrollTop)
  //     if (el.offsetTop - list_el.scrollTop < 700) {
  //       console.log('datasrc - src')
  //       el.src = el.dataset.src
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (text != null && text != undefined && text != '') {
  //     let dataUrl = parseurl(text)
  //     setListPartsMessage(dataUrl)
  //   }
  // }, [])

  // useEffect(() => {
  //   handleScroll()
  //   const list_el = document.getElementById('modermessages')
  //   console.log('addEventListener')
  //   list_el.addEventListener('scroll', handleScroll)
  //   return () => {
  //     list_el.removeEventListener('scroll', handleScroll)
  //   }
  // })

  return (
    <>
      <div className=''>
        <div className={isMobile ? 'border border-success m-1 w-75 rounded-3 bg-messageitem': 'border border-success m-1 w-35 rounded-3 bg-messageitem'}>
          <div className='text-start text-secondary m-1 fs-10 '>
            {username}
          </div>
          {infoanswer != '' ? (
            <>
              <div className='bg-answermessage  rounded-3 align-bottom m-1'>
                  <div className='d-flex justify-content-between'>
                    <div className='text-start m-1 fs-10'>
                        {infoanswer['username']}
                    </div>
                    <div className='fs-10 m-1 link-item bg-lgrey rounded p-1' onClick={() => {
                      props.setidMessageShow(infoanswer['id'])
                      props.setPage('chat')
                    }}>
                      {'в чате'}
                    </div>
                  </div>
                    
                  <div className='text-start m-1 text-wrap text-break  fs-12 text-color-white fw-bold'>
                    {infoanswer['text']}
                  </div>
                  {infoanswer['filepath'] != null && infoanswer['filepath'] != '' ? (
                      <div className='d-flex justify-content-start h-15'>
                          {IMGFILES.includes(infoanswer['filetype']) ? (
                              <>
                                  <a  
                                  // href={props.urlh + 'static/img/' + filepath} 
                                  className='d-flex justify-content-start'
                                  download 
                                  // onClick={downloadImage}
                                  >
                                  <img className='m-1 rounded-3 w-75' src={props.urlh + 'static/uploads/' + infoanswer['filepath']} />
                                  </a>
                              </>
                              ): null}
                              {infoanswer['filetype'] == 'mp4' ? (
                              <video className='m-1 rounded-3' controls="controls" >
                                  <source src={props.urlh + 'static/uploads/' + infoanswer['filepath']} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                              </video>
                              ): null}
                              {infoanswer['filetype'] == 'ogg' ? (
                                                <>
                                                <div className='w-90'>
                                                  <audio
                                                        controls
                                                        src={props.urlh + 'static/uploads/' + infoanswer['filepath']}>
                                                            Your browser does not support the
                                                            <code>audio</code> element.
                                                  </audio>
                                                </div>
                                                  
                                                </>
                                            ): null}
                              {infoanswer['filetype'] === 'pdf' || infoanswer['filetype'] === 'txt' || infoanswer['filetype'] === 'PDF' ? (
                                <object height="100%"
                                        width="100%" 
                                        className='mw-100 mh-100' 
                                        // type="text/plain" 
                                        data={props.urlh + 'static/uploads/' + infoanswer['filepath']}
                                />
                              ): null}
                              {infoanswer['filetype'] != 'mp4' && !IMGFILES.includes(infoanswer['filetype']) && infoanswer['filetype'] != 'ogg' && infoanswer['filetype'] != 'pdf' ? (
                              <a  
                                  // href={props.urlh + 'static/img/' + filepath} 
                                  download 
                                  onClick={downloadImage}
                              >
                                  <div className='m-1 rounded-3 anim'>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                          <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                      </svg>
                                  </div>
                              </a>
                              ): null}
                      </div>
                  ): null}   
              </div>  
            </>
          ): null}
          {filepath != '' && filepath != null && !DelFile ? (
            <>
              <FileItem 
                urlh={props.urlh} 
                filename={filename} 
                filetype={filetype} 
                filepath={filepath} 
                id={id}
                isMobile={isMobile} 
                setPreShowMessage={props.setPreShowMessage} 
                setSrcPreShowMessage={props.setSrcPreShowMessage} 
                setTypePreShowMessage={props.setTypePreShowMessage}
                setNameDownloadImg={props.setNameDownloadImg}
                removeEvListScroll={removeEvListScroll}
              />
            </>
            ): null} 

          

          <div className='d-flex justify-content-between'>
            <div className='m-1 rounded-3 bg-messageitem w-100'>
                {changeMode ? (
                  <>
                    <div className="w-100 p-0 m-0">
                      <TextareaAutosize  
                        id='inputmessage' 
                        type="text" 
                        className="form-control " 
                        value={Message} 
                        placeholder="Сообщение" 
                        onChange={(e) => setMessage(e.target.value)}/>
                    </div>
                  </>
                ):
                (
                  <>
                    <div className='text-start m-1 text-wrap text-break wa'>
                    {/* <p className='wa'>
                      {text}
                      </p> */}
                      {/* <p className='wa p-0 m-0 '>{beforText + ' '}</p>
                      <a href={urlText} target="_blank">{urlText}</a>
                      <p className='wa p-0 m-0'>{' ' + afterText}</p> */}

                      {renderedListPartsMessage}
                    </div> 
                    {/* <p>{text}</p> */}
                  </>
                )}
            </div>
          </div>

          {changeMode ? (
            <>
              <div className='d-flex justify-content-end'>
                <button 
                  className='btn btn-info m-1 fs-10'
                  onClick={() => {
                        setDelFile(!DelFile)
                      }}>
                  {!DelFile ? 'Удалить вложение': 'Вернуть вложение'}
                </button>
                <button 
                  className='btn btn-info m-1 fs-10'
                  onClick={() => {
                        props.handleUpdateMessage(id, Message, DelFile)
                        setChangeMode(false)
                      }}>
                  {'Сохранить'}
                </button>
              </div>
            </>
          ): (
            <>
              {!props.isFranch || props.isModer ? (
                <div className='d-flex justify-content-end'>
                  <button 
                    className='btn btn-secondary m-1 fs-10'
                    onClick={() => {
                          props.handleDeleteMessage(id)
                        }}>
                    {'Удалить'}
                  </button>
                  <button 
                    className='btn btn-secondary m-1 fs-10'
                    onClick={() => {
                          setMessage(text)
                          setChangeMode(true)
                        }}>
                    {'Изменить'}
                  </button>
                  <button 
                    className='btn btn-secondary m-1 fs-10'
                    onClick={() => {
                          console.log('acceptMessage')
                          props.handleAcceptMessage(id, username)
                        }}>
                    {'Принять'}
                  </button>
                </div>
              ): null}
            </>
          )}

          <div className='d-flex justify-content-between'>
            <p className='ms-1 mb-1 fs-10'>{textTime}</p>
            <p className='me-1 mb-1 fs-10'>{textDate}</p>
          </div>
        </div>
      </div>
    </>
  )
}