import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {parseurl} from '../lib/funcs'
import FileItem from '../lib/components';

const IMGFILES = ['png', 'jpg', 'peg', 'jpeg', 'JPG', 'PNG']

export default function MessageItem(props) {
  const text = props.text
  const username = props.username
  const isOwn = props.isOwn
  const filepath = props.filepath
  const filetype = props.filetype
  const filename = props.filename
  const id = props.id
  const id_find = props.id.toString() + 'f'
  const isfav = props.isfav
  const ischeck = props.ischeck
  const infoanswer = props.infoanswer
  const textDate = props.textDate
  const textTime = props.textTime
  const checkMessageForExport = props.checkMessageForExport
  // const files = props.files

  const [selected, setSelected] = useState(null);
  const [beforText, setBeforText] = useState('')
  const [afterText, setAfterText] = useState('')
  const [urlText, setUrlText] = useState('')
  const [listPartsMessage, setListPartsMessage] = useState([])

  const isMobile = props.isMobile

  const options = [
    'Ответить', 'Избранное'
  ];
  const defaultOption = options[0];

  const downloadImage = () => {
    // console.log('saave')
    saveAs(props.urlh + 'static/img/' + filepath) // Put your image url here.
    // fetch(props.urlh + 'static/img/' + filepath, {method: "GET", mode: 'no-cors',})
    // .then((data) => {
    //     console.log(data);
    //   });
  }

  const downloadFile = (filepath) => {
    // console.log('saave')
    saveAs(props.urlh + 'static/img/' + filepath) // Put your image url here.
    // fetch(props.urlh + 'static/img/' + filepath, {method: "GET", mode: 'no-cors',})
    // .then((data) => {
    //     console.log(data);
    //   });
  }

  const renderedListPartsMessage = listPartsMessage.map(part => {
    return (
      <>
        {part.type == 'text' ? (
          <p className='wa p-0 m-0'>{' ' + part.text + ' '}</p>
        ): (
          <a href={part.text} target="_blank">{part.text}</a>
        )}
      </>
    )
  })

  // const handleScroll = e => {
  //   var objDiv = document.getElementById("chat");
  //   const el = document.getElementById(id)
  //   if (el !== null) {
  //     // console.log('s', el.offsetTop, objDiv.scrollTop)
  //     if (el.offsetTop - objDiv.scrollTop < 700) {
  //       el.src = el.dataset.src
  //     }
  //   }
    
  // }

  useEffect(() => {
    if (text != null && text != undefined && text != '') {
      let dataUrl = parseurl(text)
      setListPartsMessage(dataUrl)
      // setBeforText(dataUrl['textbefore'])
      // setAfterText(dataUrl['textafter'])
      // setUrlText(dataUrl['url'])
    }
  }, [])

  // useEffect(() => {
  //   var objDiv = document.getElementById("chat");
  //   handleScroll()
  //   // console.log('addEventListener')
  //   objDiv.addEventListener('scroll', handleScroll)
  //   return () => {
  //     objDiv.removeEventListener('scroll', handleScroll)
  //   }
  // })

  return (
    <>
      <div id={id_find}  className={isOwn ? 'd-flex justify-content-end': 'd-flex justify-content-start'}>
        
        <div className={
          isOwn ? 
            (isMobile ? 
              'bg-messageitem m-1 rounded-3 border border-secondary': 
              'bg-messageitem m-1 w-35 rounded-3 border border-secondary'): 
            (isMobile ? 'm-1 rounded-3 bg-messageitemR border border-secondary': 'm-1 w-35 rounded-3 bg-messageitemR border border-secondary')
        }>
          {/* answer, favorite */}
          <div className='d-flex justify-content-between'>
            <div className='text-start text-secondary m-1 fs-10 w-25'>
              {username}
            </div>
            <div onClick={() => {
              props.handleUsersIsRead(id)
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
              </svg>
            </div>
            <select
              className='myControlDD p-1 w-25 m-1'
              onChange={(e) => {
                if (e.target.value == 1) {
                  props.setInpFocus( id, username, text, filepath, filetype)
                }
                else if (e.target.value == 2) {
                  props.setFavorite( id )
                }
                
              }}
              value={""}
            >
              <option value=""></option>
              {!props.isFranch || props.isModer ? (<option value="1">{'Ответить'}</option>): null}
              <option value="2">{'Избранное'}</option>
            </select>
          </div>
          

          {/* answer message */}
          {infoanswer != '' && !props.preShowMessage ? (
            <>
              <div className='bg-answermessage  rounded-3 align-bottom ms-1 me-2 position-relative zi-1'>
                  <div className='text-start m-1 fs-10'>
                      {infoanswer['username']}
                  </div>
                  {infoanswer['filetype'] == 'ogg' ? (
                                    <>
                                    <div className='w-90'>
                                      <audio
                                          controls
                                          src={props.urlh + 'static/uploads/' + infoanswer['filepath']}>
                                              Your browser does not support the
                                              <code>audio</code> element.
                                      </audio>
                                    </div>
                                    
                                    </>
                                ): null}
                  <div className='row conteinerAnswerMessage'>
                    <div className='col-8 text-start m-1 text-wrap text-break fs-12 text-color-white fw-bold zi-1'>
                      {infoanswer['text']}
                    </div> 
                    {infoanswer['filepath'] != null && infoanswer['filepath'] != '' ? (
                        <div className='col-3 d-flex justify-content-start h-m5 m-0 p-0 overflow-hidden'>
                                {IMGFILES.includes(infoanswer['filetype']) ? (
                                  <>
                                      <a  
                                      // href={props.urlh + 'static/img/' + filepath} 
                                      className='d-flex justify-content-start'
                                      download 
                                      // onClick={downloadImage}
                                      >
                                      <img className='m-1 rounded-3' src={props.urlh + 'static/uploads/' + infoanswer['filepath']} />
                                      </a>
                                  </>
                                  ): null}
                                {infoanswer['filetype'] == 'mp4' || infoanswer['filetype'] == 'mov' || infoanswer['filetype'] == 'MOV' ? (
                                <video className='m-1 rounded-3 w-100' controls="controls" preload='metadata'>
                                    <source src={props.urlh + 'static/uploads/' + infoanswer['filepath'] + '#t=0.5'} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                                </video>
                                ): null}
                                
                                {infoanswer['filetype'] == 'pdf' || infoanswer['filetype'] == 'txt' || infoanswer['filetype'] == 'PDF' ? (
                                  <object height="100%"
                                          width="100%" 
                                          className='mw-100 mh-100' 
                                          // type="text/plain" 
                                          data={props.urlh + 'static/uploads/' + infoanswer['filepath']}
                                  />
                                ): null}
                                {infoanswer['filetype'] != 'mp4' && !IMGFILES.includes(infoanswer['filetype']) && infoanswer['filetype'] != 'ogg' && infoanswer['filetype'] != 'pdf' ? (
                                <a  
                                    // href={props.urlh + 'static/img/' + filepath} 
                                    download 
                                    onClick={downloadImage}
                                >
                                    <div className='m-1 rounded-3 anim'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                            <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                        </svg>
                                    </div>
                                </a>
                                ): null}
                        </div>
                    ): null}  
                  </div>
                  
                  <div onClick={() => {
                    props.findAnswerMessage(infoanswer['id'])
                  }}>
                    <a href='#' className='stretched-link'></a> 
                  </div>                   
              </div>  
            </>
          ): null}
          {filepath != '' &&  filepath != null ? (
            <>
              <FileItem 
                setPreShowMessage={props.setPreShowMessage} 
                setSrcPreShowMessage={props.setSrcPreShowMessage} 
                setTypePreShowMessage={props.setTypePreShowMessage}
                setNameDownloadImg={props.setNameDownloadImg}
                isMobile={isMobile} 
                urlh={props.urlh} 
                filename={filename} 
                filetype={filetype} 
                filepath={filepath} 
                id={id}
                removeEvListScroll={props.removeEvListScroll}
              />
            </>
            ): null} 

          <div className={isMobile ? 'text-start m-1 text-wrap text-break text-messageM': 'text-start m-1 text-wrap text-break'}>

              {/* <p className='wa p-0 m-0'>{beforText + ' '}</p>
              <a href={urlText} target="_blank">{urlText}</a>
              <p className='wa p-0 m-0'>{' ' + afterText}</p> */}

              {renderedListPartsMessage}
          </div>
          <div className='d-flex justify-content-end p-1'>
            {isfav ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
              </svg>
            ): ''}
          </div>   
          <div className='d-flex justify-content-between'>
            <p className='ms-1 mb-1 fs-10'>{textTime}</p>
            {props.showCheckBoxMessage ? 
            (<div class="form-check">
              <input class="form-check-input" type="checkbox" checked={ischeck} id="flexCheckDefault" onChange={() => {checkMessageForExport(id)}}/>
              <label class="form-check-label" for="flexCheckDefault">
                В выгрузку
              </label>
            </div>):null
            }
            <p className='me-1 mb-1 fs-10'>{textDate}</p>
          </div>
        </div>
      </div>
    </>
  )
}