import React, { useEffect, useState } from 'react'

import MessageItem from "./MessageItem"
import ClipLoader from "react-spinners/ClipLoader";
import { sendModerator, sendAcceptMessage, sendDeleteMessageModer, sendUpdateMessageModer } from '../lib/funcs'
import { useSocket } from '../hooks/useSocket';
// import socket from '../lib/funcs'


export function MessageModer(props) {
    const { socket } = useSocket()
    const [ListMessages, setListMessage] = useState([])
    const [Message, setMessage] = useState('')
    const [showSpinnerLoad, setShowSpinnerLoad] = useState(false)
    let listMess = []
    var key = 0





    function newMessageModer(messages) {
        // socket.on('newmessagemoder', (data) => {handleNewMessage(data)})
        listMess = messages
        console.log('messages', messages)
        setListMessage(messages)
        console.log('messages.length', messages.length)
    }

    const renderedListMessages = ListMessages.map(message => {
        return <MessageItem
            key={message.id}
            id={message.id}
            username={message.username}
            text={message.text}
            url_text={message.url_text}
            filepath={message.filepath}
            filetype={message.filetype}
            filename={message.filename}
            infoanswer={message.infoanswer}
            handleAcceptMessage={handleAcceptMessage}
            handleDeleteMessage={handleDeleteMessage}
            handleUpdateMessage={handleUpdateMessage}
            url={props.url}
            urlh={props.urlh}
            textDate={message.date.textDate}
            textTime={message.date.textTime}
            isFranch={props.isFranch}
            isModer={props.isModer}
            setidMessageShow={props.setidMessageShow}
            setPage={props.setPage}
            isMobile={props.isMobile}
            setSrcPreShowMessage={props.setSrcPreShowMessage}
            setTypePreShowMessage={props.setTypePreShowMessage}
            setPreShowMessage={props.setPreShowMessage}
            setNameDownloadImg={props.setNameDownloadImg}
            ListMessages={ListMessages}
        // files={message.files}
        />

    })


    // const handleClose = () => setShowReg(false);
    function displayNotification() {
        // if (Notification.permission == 'granted') {
        //   navigator.serviceWorker.getRegistration().then(function(reg) {
        //     reg.showNotification('New message for moderation');
        //   });
        // }
        Notification.requestPermission(function (result) {
            if (result === 'granted') {
                navigator.serviceWorker.ready.then(function (registration) {
                    registration.showNotification('Vibration Sample', {
                        body: 'Buzz! Buzz!',
                        // icon: '../images/touch/chrome-touch-icon-192x192.png',
                        // vibrate: [200, 100, 200, 100, 200, 100, 200],
                        // tag: 'vibration-sample'
                    });
                });
            }
        });
    }

    // const handleScroll = e => {
    //   const el = document.getElementById(87)
    //   console.log('s', el.offsetTop, window.scrollY)
    // }
    function handleListMessage(arr) {
        listMess = arr
        setListMessage(arr)
    }

    function addNewMessagetoListMessages(newmessage) {
        let arr = listMess.slice()
        // console.log('listMess', listMess)
        arr.push(newmessage)
        // console.log(arr)
        handleListMessage(arr)
    }

    function setDeleteMessage(id_message) {
        let arr = []
        ListMessages.forEach((message) => {
            if (message.id != id_message) {
                arr.push(message)
            }
        })
        setListMessage(arr)
    }

    function setUpdateMessage(id_message, text, DelFile, url_text) {
        console.log('id_message', id_message, text)
        let arr = ListMessages.slice()
        console.log('arr', arr)
        arr.forEach((message) => {

            if (message.id == id_message) {
                console.log('message.id', message.id)
                message.text = text
                message.url_text = url_text
                if (DelFile) {
                    message.filename = ''
                    message.filetype = ''
                    message.filepath = ''
                }
            }
        })
        console.log('arr', arr)
        setListMessage(arr)
    }

    function handleModerator(id_user, id_project) {
        console.log('handleModerator')
        setShowSpinnerLoad(true)
        sendModerator(id_user, id_project, function (data) {
            setShowSpinnerLoad(false)
            newMessageModer(data['messages'])
        })

    }

    function handleNewMessage(data) {
        displayNotification()
        // console.log('ListMessages.length', ListMessages.length)
        // console.log('newmessagemoder', data, key == data['newmessage'].id)
        addNewMessagetoListMessages(data['newmessage'])
    }

    function handleAcceptMessage(id_message, username) {
        sendAcceptMessage(id_message, username, props.ChatName, function (data) {
            if (data.res) {
                setDeleteMessage(id_message)
            }
        })
    }

    function handleDeleteMessage(id_message) {
        sendDeleteMessageModer(id_message, function (data) {
            if (data.res) {
                setDeleteMessage(id_message)
            }
        })
    }

    function handleUpdateMessage(id_message, text, DelFile) {

        sendUpdateMessageModer(id_message, text, DelFile, function (data) {
            if (data.res) {
                setUpdateMessage(id_message, text, DelFile, data.url_text)
            }
        })
    }

    const handleSocketOn = (data) => {
        console.log('message from server', data);
        if (data.type === 'newmsgmoder') {
            if (data.project_id === props.idProject) handleModerator(props.UserId, props.idProject)
        }
    }


    useEffect(() => {
        console.log('newmessagemoder on')

        handleModerator(props.UserId, props.idProject)

    }, [])

    useEffect(() => {
        console.log('subscribe socket', props.UserId.toString())
        socket.on(props.UserId.toString(), handleSocketOn);
        return () => {
            socket.removeAllListeners(props.UserId.toString());
        }
    }, []);


    return (
        <>
            {showSpinnerLoad ? (<div className='position-absolute top-50 start-50 translate-middle'>
                <ClipLoader color={'black'} size={45} />
            </div>) : null}
            <div id='modermessages' data-bs-spy="scroll" data-bs-offset="0" className='h-80 overflow-auto'>
                <ul className=''>
                    {renderedListMessages}
                </ul>
            </div>
        </>
    )
}