import React, { useEffect, useState } from 'react'
// import { useSelector, useDispatch} from 'react-redux'
// import {doLoginAsync, selectIsLogin, selectUserId, doUnLoginAsync, doRegAsync} from './messagemoderSlice'

// import client from "socket.io-client"
import MessageItem from "./MessageItem"

import { sendFavoriteMessages, sendDelMessageFav} from '../lib/funcs';
// import { useRecordWebcam } from 'react-record-webcam'

import ReactToPrint from 'react-to-print';
import {ExportChat} from '../chat/exportChat';

export function MessageFav(props) {
    const [ListMessages, setListMessage] = useState([])
    const [Socket, setSocket] = useState(null)
    const [Message, setMessage] = useState('')
    const [exportChatShow, setExportChatShow] = useState(false)

    const [leftInd, setLeftInd] = useState(0)
    const [rightInd, setRightInd] = useState(0)
    
    var left_ind = 0

    var tick = 0

    let componentRef

    function handleScroll () {
        let currentTick = new Date().getTime() / 1000
        if  (currentTick - tick > 0.1) {
            var objDiv = document.getElementById("message_fav");
            // console.log('objDiv.scrollTop', objDiv.scrollTop, leftInd)
            if (objDiv.scrollTop < 200 && left_ind != 0) {
                if (left_ind > 19) {
                    setLeftInd(left_ind - 20)
                    left_ind -= 20
                } 
                else {
                    setLeftInd(0)
                    left_ind = 0
                }
                console.log('leftInd', left_ind)
            }
            
            else if (objDiv.scrollTop > objDiv.scrollHeight - 200 && rightInd != 0 ) {

            }
            
            // console.log('tick', tick, currentTick)
            tick = new Date().getTime() / 1000
        }
        
      }
    
    // const dispatch = useDispatch();
    function addScrollHandle() {
        var objDiv = document.getElementById("message_fav");
        // console.log('addEventScroll Listener')
        if (objDiv != null && objDiv != undefined) {
            objDiv.addEventListener('scroll', handleScroll)
            return () => {
            objDiv.removeEventListener('scroll', handleScroll)
            // console.log('removeEventListener Listener')
            }
        }
    }

    function newMessageFav(messages) {
        setListMessage(messages)
        setContentFav(messages)
        addScrollHandle()
    }

    function setScroll() {
        var objDiv = document.getElementById("message_fav");
        objDiv.scrollTop = objDiv.scrollHeight
        console.log('scroll', objDiv.scrollHeight, objDiv.scrollTop)
        objDiv.scrollTop = objDiv.scrollHeight
    }

    function setContentFav(messages) {
        console.log('setContentFav', messages.length)
        if (messages.length < 20) {
            setLeftInd(0)
            left_ind = 0
        }
        else {
            setLeftInd(messages.length - 20)
            left_ind = messages.length - 20
        }
        
        setRightInd(messages.length)
    }

    function removeEvListScroll() {
        console.log('removeEvListScroll')
    }
    
    const renderedListMessages = ListMessages.slice(leftInd, rightInd).map(message => {
        return <MessageItem 
            key={message.id} 
            id={message.id}
            username={message.username}
            text={message.text}
            filepath={message.filepath}
            filetype={message.filetype}
            filename={message.filename}
            infoanswer={message.infoanswer}
            handleDelMessageFav={handleDelMessageFav}
            url={props.url}
            urlh={props.urlh}
            id_user={props.UserId}
            textDate={message.date.textDate}
            textTime={message.date.textTime}
            setidMessageShow={props.setidMessageShow}
            setPage={props.setPage}
            isMobile={props.isMobile}
            setSrcPreShowMessage={props.setSrcPreShowMessage}
            setTypePreShowMessage={props.setTypePreShowMessage}
            setPreShowMessage={props.setPreShowMessage}
            setNameDownloadImg={props.setNameDownloadImg}
            removeEvListScroll={removeEvListScroll}
            preShowMessage={props.preShowMessage}
          />
          
      })
    
    

    function setDeleteMessage(id_message) {
    let arr = []
    ListMessages.forEach((message) => {
        if (message.id != id_message) {
            arr.push(message)
        }
    })
    setListMessage(arr)
    }
    
    function handleFavoriteMessages(id_user, id_project) {
        sendFavoriteMessages(id_user, id_project, function(data) {
            newMessageFav(data['messages'])
        })
    }

    function handleDelMessageFav(id_message, id_user) {
        console.log('handleDelMessageFav')
        sendDelMessageFav(id_message, id_user, function(data) {
            if (data.res) {
                console.log(data)
                setDeleteMessage(id_message)
            }
        })
    }

    

    useEffect(() => {
        props.setidMessageShow(-1)
        handleFavoriteMessages(props.UserId, props.idProject)
      }, [Socket])

    useEffect(() => {
        if (leftInd == rightInd - 20 && rightInd == ListMessages.length) {
            setScroll()
        }
        console.log('setscroll effect', leftInd, rightInd, ListMessages.length)
        
    }, [rightInd])

    return (
        <>
            {!exportChatShow ? (
                <>
                    <div id='message_fav' data-bs-spy="scroll" data-bs-offset="0" className='h-80 overflow-auto'>
                        <ul className=''>
                            {renderedListMessages}
                        </ul>
                        
                    </div>  
                    <button className="btn btn-dark" onClick={() => {
                        setExportChatShow(true)
                    }}>
                        {'Экспорт'}
                    </button>
                </>
            
            ): null}
            {exportChatShow ? (
                <>
                    <ExportChat listRows={ListMessages} urlh={props.urlh} UserId={props.UserId} ref={(response) => (componentRef = response)}/>
                    <ReactToPrint
                        content={() => componentRef}
                        trigger={() => <button className="btn btn-dark">Print to PDF</button>}
                    />
                    <button className="btn btn-outline-dark ms-2" onClick={() => {setExportChatShow(false)}}>
                        {'Назад'}
                    </button>
                </>
            ): null} 
            {/* <MyCamera urlh={props.urlh}/> */}
        </>
    )
}