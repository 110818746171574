import React, { useEffect } from 'react';
// import logo from './logo.svg';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import './App.css';
import Main from './features/main';


function App() {
    // const {tg} = useTelegram()

    // useEffect(()=>{tg.ready()})

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/:page_id"
                        element={
                            <Main />
                        }
                    />
                    <Route
                        path="/"
                        element={
                            <Main />
                        }
                    />
                    {/* <Route
                path="/deal/:deal_id"
                element={
                <Deal/>
                }
            /> */}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
